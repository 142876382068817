const clone = require("ramda/src/clone");

export const randomizeList = (list: any[]) => {
  let buf = [];
  const _list = clone(list);
  while (_list.length > 0) {
    const idx = Math.trunc(Math.random() * _list.length);
    // const idx = randomInt(0, _list.length);
    buf.push(_list[idx]);
    _list.splice(idx, 1);
  }
  return buf;
};
