import React, { useEffect, useState } from "react";
import { selectPropertyValue, selectUserInfo } from "../store/selectors";
import { ScheduleType, StudentType } from "../models/ModelTypes";
import { useSelector } from "react-redux";
import { updateThunk } from "../store/thunks";
import { useAppDispatch } from "../utilities/hooks";
import "./Intervention.css";
import { toGradeLevel } from "../utilities/toGradeLevel";

const Intervention = (props: { forTeacher?: boolean }) => {
  const [teacherList, setTeacherList] = useState<string[]>([]);
  const [studentList, setStudentList] = useState<StudentType[]>([]);
  const [periodList, setPeriodList] = useState<string[]>([]);
  const [teacherFilter, setTeacherFilter] = useState<string>("");
  const [periodFilter, setPeriodFilter] = useState<string>("");
  const [interventionSort, setInterventionSort] = useState<string>("");
  const [gradeFilter, setGradeFilter] = useState<string>("");

  const schedules = useSelector(
    selectPropertyValue("schedules")
  ) as ScheduleType[];
  const students = useSelector(
    selectPropertyValue("students")
  ) as StudentType[];
  const userInfo = useSelector(selectUserInfo);
  const [interventionComplete, setInterventionComplete] = useState<boolean>(
    userInfo.interventionFlaggingComplete
  );
  const token = useSelector(selectPropertyValue("id_token")) as string;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!userInfo?.firstName || !userInfo?.lastName) return;
    const thisTeacher = teacherList.filter(
      (t) =>
        t.toLowerCase().indexOf(userInfo.lastName.toLowerCase()) >= 0 &&
        t.toLowerCase().indexOf(userInfo.firstName.toLowerCase()) >= 0
    );
    if (thisTeacher.length === 1) {
      setTeacherFilter(thisTeacher[0]);
    }
  }, [userInfo?.firstName, userInfo?.lastName, teacherList]);

  // Filter schedule entries by teacher and period
  useEffect(() => {
    const { teacherSet, periodSet } = schedules.reduce(
      (list, schedule) => {
        if (schedule.teacher) {
          list.teacherSet.add(schedule.teacher);
        }
        if (schedule.period) {
          list.periodSet.add(schedule.period);
        }
        return list;
      },
      { teacherSet: new Set<string>(), periodSet: new Set<string>() }
    );
    const teacherList = Array.from(teacherSet)
      .filter((a) => a.length > 0)
      .sort((a, b) => (a < b ? -1 : 1));
    setTeacherList(teacherList);
    const periodList = Array.from(periodSet).sort((a, b) => (a < b ? -1 : 1));
    setPeriodList(periodList);
  }, [schedules]);

  // Filter and sort students for display
  useEffect(() => {
    const filteredSchedule = schedules.filter((s) => {
      if (teacherFilter.length > 0 && s.teacher !== teacherFilter) {
        return false;
      }
      return !(periodFilter.length > 0 && s.period !== periodFilter);
    });
    const filteredStuIds = filteredSchedule.map((s) => s.stuId);
    const sortedStudents = students
      .filter((s) => filteredStuIds.includes(s.stuId))
      .filter(
        (s) => gradeFilter === "" || gradeFilter === toGradeLevel(s.gradeLevel)
      )
      .sort((a, b) => {
        if (interventionSort !== "") {
          const ai = getIntervention(interventionSort, a);
          const bi = getIntervention(interventionSort, b);
          if (ai && !bi) {
            return -1;
          } else if (!ai && bi) {
            return 1;
          }
        }
        return a.lastName.toLowerCase() + "," + a.firstName.toLowerCase() <
          b.lastName.toLowerCase() + "," + b.firstName.toLowerCase()
          ? -1
          : 1;
      });
    setStudentList(sortedStudents);
  }, [
    students,
    schedules,
    teacherFilter,
    periodFilter,
    interventionSort,
    gradeFilter,
  ]);

  const getIntervention = (i: string, s: StudentType) => {
    switch (i) {
      case "math":
        return s.math;
      case "eng":
        return s.eng;
      case "sci":
        return s.sci;
      case "lu":
        return s.lu;
      case "sh":
        return s.sh;
      default:
        return "";
    }
  };

  const toggleIntervention = (
    evt: React.MouseEvent<HTMLTableDataCellElement>
  ) => {
    evt.currentTarget.classList.add("change-in-process");
    const _id = evt.currentTarget.getAttribute("data-id");
    const type = evt.currentTarget.getAttribute("data-type");
    const state = evt.currentTarget.getAttribute("data-state") === "true";
    if (!_id || !type) return;
    dispatch(
      updateThunk({
        collectionName: "students",
        records: [
          {
            _id,
            [type]: !state,
            interventionBlame: {
              [type]: !state
                ? userInfo.firstName + " " + userInfo.lastName
                : "",
            },
          },
        ],
        deleteFirst: false,
        token,
      })
    );
  };

  const clearAllInterventions = () => {
    if (!window.confirm("Are you sure? This cannot be undone")) return;
    const newStudents = JSON.parse(
      JSON.stringify(studentList)
    ) as StudentType[];
    const records = newStudents.map((s) => {
      return {
        _id: s._id,
        math: false,
        eng: false,
        sci: false,
        sh: false,
        lu: false,
        interventionBlame: {
          math: "",
          eng: "",
          sci: "",
          sh: "",
          lu: "",
        },
      };
    });
    dispatch(
      updateThunk({
        collectionName: "students",
        records,
        deleteFirst: false,
        token,
      })
    );
  };

  const onTeacherFilterChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    setTeacherFilter(evt.currentTarget.value);
  };

  const onPeriodFilterChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    setPeriodFilter(evt.currentTarget.value);
  };

  const toggleAllDone = () => {
    dispatch(
      updateThunk({
        collectionName: "users",
        records: [
          {
            _id: userInfo?._id,
            interventionFlaggingComplete: !interventionComplete,
          },
        ],
        deleteFirst: false,
        token,
      })
    );
    setInterventionComplete((n) => !n);
  };

  const interventionSortChange = (evt: React.MouseEvent<HTMLButtonElement>) => {
    const i = evt.currentTarget.getAttribute("data-type") ?? "";
    if (i === interventionSort) {
      setInterventionSort("");
    } else {
      setInterventionSort(i);
    }
  };

  const gradeFilterChange = (evt: React.MouseEvent<HTMLButtonElement>) => {
    const g = evt.currentTarget.getAttribute("data-type") ?? "";
    if (g === gradeFilter) {
      setGradeFilter("");
    } else {
      setGradeFilter(g);
    }
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="mb-3 row">
          <label
            htmlFor="teacherList"
            className="col-sm-1 col-form-label text-end"
          >
            Teacher:
          </label>
          <div className="col-sm-3">
            <select
              className="form-select"
              id="teacherList"
              onChange={onTeacherFilterChange}
              value={teacherFilter}
            >
              <option key="blank" value="">
                All teachers
              </option>
              {teacherList.map((t) => (
                <option key={t} value={t}>
                  {t}
                </option>
              ))}
            </select>
          </div>
          <label
            htmlFor="periodList"
            className="col-sm-1 col-form-label text-end"
          >
            Period:
          </label>
          <div className="col-sm-2">
            <select
              className="form-select"
              id="periodList"
              onChange={onPeriodFilterChange}
            >
              <option key="blank" value="">
                All periods
              </option>
              {periodList.map((p) => (
                <option key={p} value={p}>
                  Period {p}
                </option>
              ))}
            </select>
          </div>
          <div className="col-sm-2">
            <button
              className={
                gradeFilter === "6"
                  ? "btn btn-sm btn-primary"
                  : "btn btn-sm btn-secondary"
              }
              onClick={gradeFilterChange}
              data-type="6"
            >
              Gr 6
            </button>
            <button
              className={
                gradeFilter === "7"
                  ? "btn btn-sm btn-primary"
                  : "btn btn-sm btn-secondary"
              }
              onClick={gradeFilterChange}
              data-type="7"
            >
              Gr 7
            </button>
            <button
              className={
                gradeFilter === "8"
                  ? "btn btn-sm btn-primary"
                  : "btn btn-sm btn-secondary"
              }
              onClick={gradeFilterChange}
              data-type="8"
            >
              Gr 8
            </button>
          </div>
          <div className="col-sm-3 text-end">
            {props.forTeacher ? (
              <button
                className={
                  interventionComplete ? "btn btn-success" : "btn btn-danger"
                }
                onClick={toggleAllDone}
              >
                {interventionComplete ? "Make more changes" : "I'm done!"}
              </button>
            ) : null}
            <button className="btn btn-danger" onClick={clearAllInterventions}>
              Clear visible interventions
            </button>
          </div>
        </div>
      </div>
      <div className="col-md-12 intervention-scroller">
        <table className="table table-sm table-bordered">
          <thead>
            <tr>
              <th className="text-center col-md-2">ID</th>
              <th className="text-center col-md-1">Grade</th>
              <th className="text-start col-md-4">Student name</th>
              <th className="text-center col-md-1">
                <button
                  onClick={interventionSortChange}
                  className={
                    interventionSort === "math"
                      ? "btn btn-primary btn-sm btn-block"
                      : "btn btn-secondary btn-sm btn-block"
                  }
                  data-type="math"
                >
                  Math
                </button>
              </th>
              <th className="text-center col-md-1">
                <button
                  onClick={interventionSortChange}
                  className={
                    interventionSort === "eng"
                      ? "btn btn-primary btn-sm btn-block"
                      : "btn btn-secondary btn-sm btn-block"
                  }
                  data-type="eng"
                >
                  Eng
                </button>
              </th>
              <th className="text-center col-md-1">
                <button
                  onClick={interventionSortChange}
                  className={
                    interventionSort === "sci"
                      ? "btn btn-primary btn-sm btn-block"
                      : "btn btn-secondary btn-sm btn-block"
                  }
                  data-type="sci"
                >
                  Sci
                </button>
              </th>
              <th className="text-center col-md-1">
                <button
                  onClick={interventionSortChange}
                  className={
                    interventionSort === "sh"
                      ? "btn btn-primary btn-sm btn-block"
                      : "btn btn-secondary btn-sm btn-block"
                  }
                  data-type="sh"
                >
                  StHall
                </button>
              </th>
              <th className="text-center col-md-1">
                <button
                  onClick={interventionSortChange}
                  className={
                    interventionSort === "lu"
                      ? "btn btn-primary btn-sm btn-block"
                      : "btn btn-secondary btn-sm btn-block"
                  }
                  data-type="lu"
                >
                  LrnUpgr
                </button>
              </th>
            </tr>
          </thead>
          <tbody className="intervention-table">
            {studentList.map((u, idx) => (
              <tr key={u._id}>
                <td>{u.stuId}</td>
                <td>{toGradeLevel(u.gradeLevel)}</td>
                <td className="name-text text-start">
                  {u.lastName}, {u.firstName}
                </td>
                <td
                  onClick={toggleIntervention}
                  data-state={u.math ? "true" : "false"}
                  data-id={u._id}
                  data-type="math"
                  title={u?.interventionBlame?.math ?? ""}
                  className={
                    u.math
                      ? "intervention-cell intervention-on"
                      : "intervention-cell intervention-off"
                  }
                >
                  Math
                </td>
                <td
                  onClick={toggleIntervention}
                  data-state={u.eng ? "true" : "false"}
                  data-id={u._id}
                  data-type="eng"
                  title={u?.interventionBlame?.eng ?? ""}
                  className={
                    u.eng
                      ? "intervention-cell intervention-on"
                      : "intervention-cell intervention-off"
                  }
                >
                  Eng
                </td>
                <td
                  onClick={toggleIntervention}
                  data-state={u.sci ? "true" : "false"}
                  data-id={u._id}
                  data-type="sci"
                  title={u?.interventionBlame?.sci ?? ""}
                  className={
                    u.sci
                      ? "intervention-cell intervention-on"
                      : "intervention-cell intervention-off"
                  }
                >
                  Sci
                </td>
                <td
                  onClick={toggleIntervention}
                  data-state={u.sh ? "true" : "false"}
                  data-id={u._id}
                  data-type="sh"
                  title={u?.interventionBlame?.sh ?? ""}
                  className={
                    u.sh
                      ? "intervention-cell intervention-on"
                      : "intervention-cell intervention-off"
                  }
                >
                  StHall
                </td>
                <td
                  onClick={toggleIntervention}
                  data-state={u.lu ? "true" : "false"}
                  data-id={u._id}
                  data-type="lu"
                  title={u?.interventionBlame?.lu ?? ""}
                  className={
                    u.lu
                      ? "intervention-cell intervention-on"
                      : "intervention-cell intervention-off"
                  }
                >
                  LrnUpgr
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Intervention;
