import React, { useEffect, useState } from "react";
import { selectPropertyValue } from "../../store/selectors";
import {
  CoursePriorities,
  CourseType,
  EnrollmentType,
  GradeLevels,
  StudentType,
} from "../../models/ModelTypes";
import { useAppDispatch, useAppSelector } from "../../utilities/hooks";
import { removeManyThunk, updateThunk } from "../../store/thunks";
import { toGradeLevel } from "../../utilities/toGradeLevel";
import { randomlySelect as _randomlySelect } from "../../utilities/randomlySelect";
import "./Scheduler.css";
import { setDirtyAction } from "../../store/actions";

const { without, groupBy, clone, count } = require("ramda");

interface StudentWithEnrollmentType extends StudentType {
  enrollment?: EnrollmentType | null;
  isAlreadyTaken: boolean;
}

const ManualEntry = () => {
  const [selectedCourseId, setSelectedCourseId] = useState<string>("");
  const [section, setSection] = useState<string>("1");
  const [showRequestedStudents, setShowRequestedStudents] =
    useState<boolean>(true);
  const [inProcess, setInProcess] = useState<boolean>(false);
  const [gradeLevel, setGradeLevel] = useState<number>(
    GradeLevels.SIX | GradeLevels.SEVEN | GradeLevels.EIGHT
  );

  const [selectedUnenrolledStudents, setSelectedUnenrolledStudents] = useState<
    string[]
  >([]);
  const [selectedEnrolledStudents, setSelectedEnrolledStudents] = useState<
    string[]
  >([]);

  const isAlreadyTaken = (
    studentId: string,
    courseId: string,
    cycleId: string
  ) => {
    const course = courses.find((c: CourseType) => c._id === courseId);
    if (course?.priority !== CoursePriorities.EXPLORATION) return false;
    return enrollments.some(
      (e: EnrollmentType) =>
        e.studentId === studentId &&
        e.courseId === courseId &&
        e.cycleId < cycleId
    );
  };

  const settings = useAppSelector(selectPropertyValue("settings"));
  const courses = useAppSelector(selectPropertyValue("courses"));
  const thisCourse = courses.find(
    (c: CourseType) => c._id === selectedCourseId
  ) as CourseType;
  const enrollments = useAppSelector(selectPropertyValue("enrollments"));
  const students = useAppSelector(selectPropertyValue("students"))
    .filter((s: StudentType) => (s.gradeLevel & gradeLevel) > 0)
    .sort((a: StudentType, b: StudentType) =>
      a.lastName.toLowerCase() + "," + a.firstName.toLowerCase() <
      b.lastName.toLowerCase() + "," + b.firstName.toLowerCase()
        ? -1
        : 1
    )
    .map((s: StudentType): StudentWithEnrollmentType => {
      let _s: StudentWithEnrollmentType = clone(s);
      _s.enrollment =
        enrollments.find(
          (e: EnrollmentType) =>
            e.studentId === s._id && e.cycleId === settings.cycle
        ) ?? null;
      _s.isAlreadyTaken = isAlreadyTaken(
        s._id,
        selectedCourseId,
        settings.cycle
      );
      return _s;
    });

  const token = useAppSelector<string>(selectPropertyValue("id_token"));
  const updateFlag = useAppSelector<number>(selectPropertyValue("updateFlag"));
  const dispatch = useAppDispatch();

  useEffect(() => {
    setInProcess(false);
  }, [updateFlag]);

  let status =
    selectedCourseId.length > 0
      ? groupBy((s: StudentWithEnrollmentType) => {
          return s.enrollment?.courseId === selectedCourseId
            ? "enrolled"
            : "unenrolled";
        }, students)
      : { enrolled: [], unenrolled: [] };
  status.enrolled = status.enrolled ?? [];
  status.unenrolled = status.unenrolled ?? [];
  if (showRequestedStudents) {
    status.unenrolled = status.unenrolled.filter(
      (s: StudentWithEnrollmentType) => {
        if (s.isAlreadyTaken) return false;
        return s.requests.includes(selectedCourseId);
      }
    );
  }
  const { enrolled, unenrolled } = status;

  const sectionButtonClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
    setSection(evt.currentTarget.getAttribute("data-value") ?? "1");
  };

  const clickStudent =
    (type: "enrolled" | "unenrolled") =>
    (evt: React.MouseEvent<HTMLTableRowElement>) => {
      const list =
        type === "enrolled"
          ? clone(selectedEnrolledStudents)
          : clone(selectedUnenrolledStudents);
      const setter: React.Dispatch<React.SetStateAction<string[]>> =
        type === "enrolled"
          ? setSelectedEnrolledStudents
          : setSelectedUnenrolledStudents;
      const thisId = evt.currentTarget.getAttribute(
        type === "unenrolled" ? "data-id" : "data-enrollment"
      ) as string;
      if (list.includes(thisId)) {
        setter(without(thisId, list));
      } else {
        list.push(thisId);
        setter(list);
      }
    };

  const toggleShowRequestedStudents = () => {
    setShowRequestedStudents(!showRequestedStudents);
  };

  const enroll = () => {
    setInProcess(true);
    const newEnrollments = selectedUnenrolledStudents.map((sId: any) => {
      const thisStudent = students.find((s: any) => s._id === sId);

      return thisStudent?.enrollment
        ? {
            _id: thisStudent.enrollment._id,
            courseId: selectedCourseId,
            sectionId: section,
          }
        : {
            _id: "",
            cycleId: settings.cycle,
            courseId: selectedCourseId,
            sectionId: section,
            studentId: sId,
          };
    });
    setSelectedUnenrolledStudents([]);
    dispatch(
      updateThunk({
        collectionName: "enrollments",
        records: newEnrollments,
        deleteFirst: false,
        token,
      })
    );
  };

  const unenroll = () => {
    setInProcess(true);
    dispatch(
      removeManyThunk({
        collectionName: "enrollments",
        ids: selectedEnrolledStudents,
        token,
      })
    );
    setSelectedEnrolledStudents([]);
  };

  const randomlySelect = () => {
    const enrolledCount = count(
      (s: any) =>
        s.enrollmentRecord &&
        s.enrollmentRecord.courseId === selectedCourseId &&
        s.enrollmentRecord.sectionId === section,
      students
    );
    let space =
      thisCourse.classSize - enrolledCount - selectedUnenrolledStudents.length;
    const sus = _randomlySelect(unenrolled, selectedUnenrolledStudents, space);
    setSelectedUnenrolledStudents(sus);
  };

  const createSectionButtons = () => {
    if (!thisCourse) return;
    const arr = [];
    for (let i = 1; i <= thisCourse.sectionCount; i++) {
      const sectionCount = count(
        (student: StudentWithEnrollmentType) =>
          student.enrollment?.courseId === selectedCourseId &&
          student.enrollment?.sectionId + "" === i + "",
        students
      );
      let className = "btn btn-enroll btn-block mb-3 ";
      className += i + "" === section + "" ? "btn-success" : "btn-secondary";
      arr.push(
        <button
          onClick={sectionButtonClick}
          key={"section" + i}
          className={className}
          disabled={false}
          data-value={i}
        >
          {`Sec ${i} (${sectionCount})`}
          <div className="intervention-teacher-name">
            {thisCourse.teacherList[i - 1]}
          </div>
        </button>
      );
    }
    return arr;
  };

  let options = [
    <option key={"--select"} value="" disabled>
      -- Select a course --
    </option>,
  ];

  const courseTypes = ["Year", "Grade", "Intervention", "Exploration"];
  options = options.concat(
    clone(courses)
      .sort((a: CourseType, b: CourseType) => {
        if (a.sectionCount === 0 && b.sectionCount > 0) return 1;
        if (b.sectionCount === 0 && a.sectionCount > 0) return -1;
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
      })
      .map((c: CourseType) => {
        return (
          <option key={c._id} value={c._id} disabled={c.sectionCount === 0}>
            {c.name} ({courseTypes[c.priority]})
          </option>
        );
      })
  );

  const selectCourse = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCourseId(evt.currentTarget.value);
    dispatch(setDirtyAction(false));
  };

  const selectGradeLevel = (evt: React.MouseEvent<HTMLButtonElement>) => {
    const ngl = parseInt(evt.currentTarget.getAttribute("data-gl") ?? "0", 10);
    setGradeLevel((gl) => gl ^ ngl);
  };

  return (
    <div className={inProcess ? "row update-in-process" : "row"}>
      <div className="col-md-6">
        <div className="mb-3 row">
          <label className="col-sm-3 col-form-label">Course:</label>
          <div className="col-sm-9">
            <select
              className="form-select"
              onChange={selectCourse}
              value={selectedCourseId}
            >
              {options}
            </select>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <button
          onClick={selectGradeLevel}
          data-gl={GradeLevels.SIX}
          className={
            (gradeLevel & GradeLevels.SIX) > 0
              ? "btn btn-sm btn-primary"
              : "btn btn-sm btn-secondary"
          }
        >
          6th
        </button>
        <button
          onClick={selectGradeLevel}
          data-gl={GradeLevels.SEVEN}
          className={
            (gradeLevel & GradeLevels.SEVEN) > 0
              ? "btn btn-sm btn-primary"
              : "btn btn-sm btn-secondary"
          }
        >
          7th
        </button>
        <button
          onClick={selectGradeLevel}
          data-gl={GradeLevels.EIGHT}
          className={
            (gradeLevel & GradeLevels.EIGHT) > 0
              ? "btn btn-sm btn-primary"
              : "btn btn-sm btn-secondary"
          }
        >
          8th
        </button>
        <input
          type="checkbox"
          checked={!showRequestedStudents}
          onChange={toggleShowRequestedStudents}
        />
        <span className="left-spacer">Show all students</span>
      </div>
      <div id="dim-this" className="col-md-5 scheduler-year-container">
        <table className="table table-sm table-bordered table-condensed">
          <thead>
            <tr>
              <th className="table-header">
                {`Unenrolled (${selectedUnenrolledStudents.length} / ${unenrolled.length})`}
                <button
                  className="btn btn-small btn-primary btn-randomly-select"
                  onClick={randomlySelect}
                >
                  Random
                </button>
                <button
                  className="btn btn-small btn-primary btn-randomly-select"
                  onClick={() => setSelectedUnenrolledStudents([])}
                >
                  Clear
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {unenrolled.map((s: StudentWithEnrollmentType) => {
              const selected = selectedUnenrolledStudents.includes(s._id);
              return (
                <tr
                  className={selected ? "active-student pointer" : "pointer"}
                  data-id={s._id}
                  data-enrollment={s?.enrollment?._id ?? ""}
                  onClick={clickStudent("unenrolled")}
                >
                  <td className="text-start">
                    {s.isAlreadyTaken ? (
                      <span className="already-taken">&#10003; </span>
                    ) : (
                      ""
                    )}
                    {s.lastName}, {s.firstName} (Gr:{" "}
                    {toGradeLevel(s.gradeLevel)} | ID: {s.stuId})
                    {s.enrollment && s.enrollment.courseId ? (
                      <span className="enrolled-course">
                        {" " +
                          courses.find(
                            (c: CourseType) => c._id === s.enrollment?.courseId
                          ).name}
                      </span>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="col-md-2">
        <div>
          {createSectionButtons()}
          <button
            onClick={enroll}
            className="btn btn-primary btn-enroll btn-block mb-3"
            disabled={selectedUnenrolledStudents.length === 0}
          >
            {"Enroll =>"}
          </button>
        </div>
        <div>
          <button
            onClick={unenroll}
            className="btn btn-primary btn-unenroll btn-block"
            disabled={selectedEnrolledStudents.length === 0}
          >
            {"<= Unenroll"}
          </button>
        </div>
      </div>
      <div id="dim-this-too" className="col-md-5 scheduler-year-container">
        <table className="table table-sm table-bordered table-condensed">
          <thead>
            <tr>
              <th>{`Enrolled (${selectedEnrolledStudents.length} / ${enrolled.length}) `}</th>
            </tr>
          </thead>
          <tbody>
            {enrolled.map((s: StudentWithEnrollmentType) => {
              const selected = selectedEnrolledStudents.includes(
                s.enrollment ? s.enrollment._id : ""
              );
              return (
                <tr
                  className={selected ? "active-student pointer" : "pointer"}
                  key={s._id + "me"}
                  data-id={s._id}
                  data-enrollment={s.enrollment?._id}
                  onClick={clickStudent("enrolled")}
                >
                  <td className="text-start">
                    {s.enrollment ? s.enrollment.sectionId + " " : ""}
                    {s.lastName}, {s.firstName} (Gr:{" "}
                    {toGradeLevel(s.gradeLevel)} | ID: {s.stuId})
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManualEntry;
