import React, { useEffect, useState } from "react";
import { selectPropertyValue } from "../store/selectors";
import { useAppDispatch, useAppSelector } from "../utilities/hooks";
import { UserType } from "../models/ModelTypes";
import { useSelector } from "react-redux";
import ControlButtons from "../components/ControlButtons";
import "./Users.css";
import Switch from "../components/Switch";
import { deleteThunk, updateThunk } from "../store/thunks";
import { setDirtyAction } from "../store/actions";

const Users = () => {
  const [selectedUser, setSelectedUser] = useState<UserType | null>(null);
  const [email, setEmail] = useState("");
  const [_id, set_id] = useState<string>("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [interventionFlaggingComplete, setInterventionFlaggingComplete] =
    useState(false);
  const [isNew, setIsNew] = useState(false);
  const [isDupe, setIsDupe] = useState<boolean>(false);
  const [isValid, setIsValid] = useState(false);
  const id_token = useAppSelector(selectPropertyValue("id_token"));
  const dispatch = useAppDispatch();

  const _users = useSelector<UserType[]>(selectPropertyValue("users"));
  const dirty = useSelector<boolean>(selectPropertyValue("dirty"));
  const users: UserType[] = JSON.parse(JSON.stringify(_users));
  const sortedUsers = users.sort((a, b) => (a.email < b.email ? -1 : 1));

  useEffect(() => {
    const rec = users.find((u) => u.email === email);
    const isDupe = (rec && rec._id !== _id) ?? false;
    setIsDupe(isDupe);
    setIsValid(
      !isDupe && email.length > 0 && lastName.length > 0 && firstName.length > 0
    );
  }, [_id, users, email, lastName, firstName, interventionFlaggingComplete]);

  const selectUserClick = (evt: React.MouseEvent<HTMLLIElement>) => {
    const idx = evt.currentTarget.getAttribute("data-idx");
    if (idx !== null) {
      const _idx = parseInt(idx);
      setSelectedUser(sortedUsers[_idx]);
      set_id(sortedUsers[_idx]._id);
      setEmail(sortedUsers[_idx].email);
      setIsAdmin(sortedUsers[_idx].isAdmin);
      setLastName(sortedUsers[_idx].lastName);
      setFirstName(sortedUsers[_idx].firstName);
      setInterventionFlaggingComplete(
        sortedUsers[_idx].interventionFlaggingComplete
      );
      dispatch(setDirtyAction(false));
    }
  };

  const emailChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const email = evt.currentTarget.value;
    setEmail(email);
    dispatch(setDirtyAction(true));
  };

  const isAdminChange = (newValue: boolean) => {
    setIsAdmin(newValue);
    dispatch(setDirtyAction(true));
  };

  const isInterventionFlaggingCompleteChange = (newValue: boolean) => {
    setInterventionFlaggingComplete(newValue);
    dispatch(setDirtyAction(true));
  };

  const nameChange =
    (setter: React.Dispatch<React.SetStateAction<string>>) =>
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      setter(evt.currentTarget.value);
      dispatch(setDirtyAction(true));
    };

  const addUser = () => {
    dispatch(setDirtyAction(true));
    setIsNew(true);
    setEmail("");
    set_id("");
    setLastName("");
    setFirstName("");
    setIsAdmin(false);
    setInterventionFlaggingComplete(false);
    setSelectedUser(null);
  };

  const deleteUser = () => {
    if (!selectedUser) return;
    if (
      !window.confirm(
        `Are you sure you want to delete ${firstName + " " + lastName}`
      )
    )
      return;
    dispatch(
      deleteThunk({
        _id: selectedUser._id,
        collectionName: "users",
        token: id_token,
      })
    );
  };

  const saveUser = () => {
    if (!selectedUser && !isNew) return;
    const record = {
      _id,
      email,
      firstName,
      interventionFlaggingComplete,
      isAdmin,
      lastName,
    };
    dispatch(
      updateThunk({
        collectionName: "users",
        records: [record],
        token: id_token,
        deleteFirst: false,
      })
    );
  };

  const cancelUser = () => {
    if (!selectedUser) return;
    set_id(selectedUser._id);
    setEmail(selectedUser.email);
    setIsAdmin(selectedUser.isAdmin);
    setLastName(selectedUser.lastName);
    setFirstName(selectedUser.firstName);
    setInterventionFlaggingComplete(selectedUser.interventionFlaggingComplete);
    dispatch(setDirtyAction(false));
  };

  return (
    <div className="row">
      <div className="col-md-4">
        <h5>User list</h5>
        <ul className="list-group user-list">
          {sortedUsers.map((u, idx) => (
            <li
              className={
                u._id === selectedUser?._id
                  ? "list-group-item pointer active"
                  : "list-group-item pointer"
              }
              key={u._id}
              data-idx={idx}
              onClick={selectUserClick}
            >
              {u.email}{" "}
              {u.isAdmin ? <span className="user-admin-tag">(admin)</span> : ""}
            </li>
          ))}
        </ul>
      </div>
      <div className="col-md-8">
        <h5>User information</h5>
        <ControlButtons
          addClick={addUser}
          addDisabled={dirty}
          deleteClick={deleteUser}
          deleteDisabled={dirty}
          saveClick={saveUser}
          saveDisabled={!dirty || !isValid}
          cancelClick={cancelUser}
          cancelDisabled={!dirty}
        />
        {selectedUser || isNew ? (
          <>
            <div className="mb-3 row">
              <label
                htmlFor="email"
                className="col-sm-4 col-form-label text-end"
              >
                Email:
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className={
                    isDupe ? "form-control dupe-error" : "form-control"
                  }
                  id="email"
                  onChange={emailChange}
                  value={email}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label
                htmlFor="lastName"
                className="col-sm-4 col-form-label text-end"
              >
                Last name:
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  onChange={nameChange(setLastName)}
                  value={lastName}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label
                htmlFor="firstName"
                className="col-sm-4 col-form-label text-end"
              >
                First name:
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  onChange={nameChange(setFirstName)}
                  value={firstName}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label
                htmlFor="isAdmin"
                className="col-sm-4 col-form-label text-end"
              >
                Admin:
              </label>
              <div className="form-group col-sm-8">
                <Switch
                  value={isAdmin}
                  containerClass="text-start"
                  className=""
                  trueClass="true-button"
                  falseClass="false-button"
                  trueComponent={<span className="true-button-text">Yes</span>}
                  falseComponent={<span className="false-button-text">No</span>}
                  onChange={isAdminChange}
                />
              </div>
            </div>
            {!isAdmin ? (
              <div className="mb-3 row">
                <label
                  htmlFor="interventionFlaggingComplete"
                  className="col-sm-4 col-form-label text-end"
                >
                  Intervention flag complete:
                </label>
                <div className="form-group col-sm-8">
                  <Switch
                    value={interventionFlaggingComplete}
                    containerClass="text-start"
                    className=""
                    trueClass="true-button"
                    falseClass="false-button"
                    trueComponent={
                      <span className="true-button-text">Yes</span>
                    }
                    falseComponent={
                      <span className="false-button-text">No</span>
                    }
                    onChange={isInterventionFlaggingCompleteChange}
                  />
                </div>
              </div>
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Users;
