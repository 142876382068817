import { GradeLevels } from "../models/ModelTypes";

export const toGradeLevel = (gl: number) => {
  if ((gl & GradeLevels.SIX) > 0) {
    return '6';
  } else if ((gl & GradeLevels.SEVEN) > 0) {
    return '7';
  } else if ((gl & GradeLevels.EIGHT) > 0) {
    return '8';
  } else {
    return 'Unk';
  }
}
