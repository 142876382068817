import React, { useState } from "react";
import { selectPropertyValue } from "../../store/selectors";
import {
  CoursePriorities,
  CourseType,
  EnrollmentType,
  StudentType,
} from "../../models/ModelTypes";
import { useAppDispatch, useAppSelector } from "../../utilities/hooks";
import { updateThunk } from "../../store/thunks";
import "./Scheduler.css";
import { randomizeList } from "../../utilities/randomizeList";
import { hasUpdatedAction, isUpdatingAction } from "../../store/actions";
import { groupBy } from "ramda";

const { clone } = require("ramda");
interface CourseSummaryType extends CourseType {
  requests: number;
  seats: number;
  enrollmentConfirmed: number;
  enrollmentAutoschedule: number;
}

const Autoschedule = () => {
  const [newEnrollments, setNewEnrollments] = useState<any>([]);

  const token = useAppSelector<string>(selectPropertyValue("id_token"));
  const settings = useAppSelector(selectPropertyValue("settings"));
  const enrollments = useAppSelector(selectPropertyValue("enrollments"));
  const enrollmentsThisCycle = enrollments.filter(
    (e: EnrollmentType) => e.cycleId === settings.cycle
  );
  const students = clone(useAppSelector(selectPropertyValue("students")));
  const studentsUnenrolled = students.filter((s: StudentType) => {
    return !enrollmentsThisCycle.find(
      (e: EnrollmentType) => e.studentId === s._id
    );
  });
  const courses = useAppSelector(selectPropertyValue("courses"));
  const coursesExploration = courses
    .filter((c: CourseType) => c.priority === CoursePriorities.EXPLORATION)
    .sort((a: CourseType, b: CourseType) => {
      if (a.sectionCount < b.sectionCount) {
        return 1;
      } else if (a.sectionCount > b.sectionCount) {
        return -1;
      } else {
        return a.name < b.name ? -1 : 1;
      }
    });

  const dispatch = useAppDispatch();

  const countFcn =
    (cycle: string, courseId: string, sectionId: string) =>
    (total: number, e: EnrollmentType) => {
      return e.courseId === courseId &&
        e.sectionId === sectionId &&
        e.cycleId === cycle
        ? total + 1
        : total;
    };

  const computeCounts = (
    cycle: string,
    courseId: string,
    sectionId: string,
    enrollments: EnrollmentType[]
  ): number => {
    return enrollments.reduce(countFcn(cycle, courseId, sectionId), 0);
  };

  const autoschedule = () => {
    const _newEnrollments: Omit<EnrollmentType, "_id">[] = [];
    studentsUnenrolled.forEach((s: StudentType) => {
      const list1 = s.requests.split(",");
      const list2 = list1.filter((r: string) => r.length > 0);
      const list3 = list2.filter((r: string) => {
        const res = enrollments.some((e: EnrollmentType) => {
          return (
            e.courseId === r &&
            e.studentId === s._id &&
            parseInt(e.cycleId, 10) < parseInt(settings.cycle, 10)
          );
        });
        return !res;
      });
      const requests = randomizeList(list3);
      let requestIdx = 0;
      let newEnrollment = null;
      while (requestIdx < requests.length && !newEnrollment) {
        const courseId = requests[requestIdx];
        const cs = courses.find((c: CourseSummaryType) => c._id === courseId);
        let sectionId = 1;
        while (sectionId <= cs.sectionCount && !newEnrollment) {
          const count = computeCounts(
            settings.cycle,
            courseId,
            sectionId + "",
            enrollments.concat(_newEnrollments)
          );
          if (count < cs.classSize) {
            newEnrollment = {
              cycleId: settings.cycle,
              studentId: s._id,
              courseId: cs._id,
              sectionId: sectionId + "",
            };
          }
          sectionId++;
        }
        requestIdx++;
      }
      if (newEnrollment) {
        _newEnrollments.push(newEnrollment);
      }
    });
    setNewEnrollments(_newEnrollments);
  };

  const commit = () => {
    if (!window.confirm("Are you sure you want to commit these enrollments?"))
      return;
    dispatch(isUpdatingAction(true));
    dispatch(hasUpdatedAction(false));
    dispatch(
      updateThunk({
        collectionName: "enrollments",
        records: newEnrollments,
        deleteFirst: false,
        token,
      })
    );
    setNewEnrollments([]);
  };

  const clearEnrollments = () => {
    if (
      !window.confirm(
        "Are you sure you want to clear the autoscheduled enrollments?"
      )
    )
      return;
    setNewEnrollments([]);
  };

  const alreadyTaken = (
    studentId: string,
    courseId: string,
    cycleId: number
  ) => {
    return enrollments.some(
      (e: EnrollmentType) =>
        e.studentId === studentId &&
        e.courseId === courseId &&
        parseInt(e.cycleId, 10) < cycleId
    );
  };

  const getStudentList = () => {
    return students
      .sort((a: StudentType, b: StudentType) => {
        return a.lastName.toLowerCase() + "," + a.firstName.toLowerCase() <
          b.lastName.toLowerCase() + "," + b.firstName.toLowerCase()
          ? -1
          : 1;
      })
      .map((s: StudentType) => {
        let enrollmentClass = "new-enrollment";
        let enrollment = newEnrollments.find(
          (e: EnrollmentType) => e.studentId === s._id
        );
        if (!enrollment) {
          enrollment = enrollments.find(
            (e: EnrollmentType) =>
              e.studentId === s._id && e.cycleId === settings.cycle
          );
          if (enrollment) {
            enrollmentClass = "old-enrollment";
          }
        }
        let course;
        if (!enrollment) {
          enrollmentClass = "no-enrollment";
        } else {
          course = courses.find(
            (c: CourseType) => c._id === enrollment.courseId
          );
        }
        return (
          <tr key={s._id + "sl"} className={enrollmentClass}>
            <td>{s.stuId}</td>
            <td className="text-start">
              {s.lastName}, {s.firstName}
            </td>
            <td>{course?.name ?? "---"}</td>
          </tr>
        );
      });
  };

  const htmlObj = getStudentList();

  return (
    <div className="row">
      <div className="col-md-6 scheduler-year-container">
        <div className="row">
          <div className="col-md-4">
            <button className="btn btn-primary btn-sm" onClick={autoschedule}>
              Auto schedule
            </button>
          </div>
          <div className="col-md-4">
            <button className="btn btn-primary btn-sm" onClick={commit}>
              Commit changes
            </button>
          </div>
          <div className="col-md-4">
            <button
              className="btn btn-danger btn-sm"
              onClick={clearEnrollments}
            >
              Clear proposed
            </button>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <table className="table table-sm table-condensed ">
              <thead>
                <tr>
                  <th>Course</th>
                  <th>Seats</th>
                  <th>Requests</th>
                  <th>Enrolled</th>
                  <th>EnrAftChgs</th>
                  {/*<th>Available</th>*/}
                </tr>
              </thead>
              <tbody>
                {coursesExploration.map((c: CourseType) => {
                  const enrollmentsBySection = groupBy(
                    (e: EnrollmentType) => e.sectionId,
                    enrollments.filter(
                      (e: EnrollmentType) =>
                        e.courseId === c._id && e.cycleId === settings.cycle
                    )
                  );
                  const newEnrollmentsBySection = groupBy(
                    (e: EnrollmentType) => e.sectionId,
                    newEnrollments.filter(
                      (e: EnrollmentType) =>
                        e.courseId === c._id && e.cycleId === settings.cycle
                    )
                  );
                  const enrollmentSectionNumbers =
                    Object.keys(enrollmentsBySection);
                  const newEnrollmentSectionNumbers = Object.keys(
                    newEnrollmentsBySection
                  );
                  const requestCount = students.reduce(
                    (count: number, s: StudentType) =>
                      s.requests.includes(c._id) &&
                      !alreadyTaken(s._id, c._id, settings.cycle)
                        ? count + 1
                        : count,
                    0
                  );
                  // console.log(newEnrollmentsBySection);
                  return (
                    <tr
                      key={c._id}
                      className={c.sectionCount === 0 ? "inactive-course" : ""}
                    >
                      <td className="text-start">{c.name}</td>
                      <td>{c.sectionCount * c.classSize}</td>
                      <td>{requestCount}</td>
                      <td>
                        {enrollmentSectionNumbers.length === 0
                          ? "0"
                          : enrollmentSectionNumbers.map((sn: string) => (
                              <div key={c._id + "exist" + sn}>
                                {"S" +
                                  sn +
                                  ": " +
                                  enrollmentsBySection[sn]?.length ?? "0"}
                              </div>
                            ))}
                      </td>
                      <td>
                        {newEnrollmentSectionNumbers.length === 0
                          ? "0"
                          : newEnrollmentSectionNumbers.map((sn: string) => (
                              <div key={c._id + "new" + sn}>
                                {"S" +
                                  sn +
                                  ": " +
                                  newEnrollmentsBySection[sn]?.length ?? "0"}
                              </div>
                            ))}
                      </td>
                      {/*<td>???</td>*/}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>{" "}
        </div>
      </div>
      <div className="col-md-6">
        <div className="row">
          <h6>Proposed enrollments after commit</h6>
          <h6>
            {`Existing: ${enrollmentsThisCycle.length} | Proposed:
            ${newEnrollments.length} | Not enrolled:
            ${
              students.length -
              enrollmentsThisCycle.length -
              newEnrollments.length
            }`}
          </h6>
        </div>
        <div className="row scheduler-year-container mt-3">
          <div className="col-md-12">
            <table className="table table-condensed table-sm">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Student name</th>
                  <th>Course name</th>
                </tr>
              </thead>
              <tbody>{students ? htmlObj : null}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Autoschedule;
