import React, { useEffect, useState } from "react";
import { selectPropertyValue } from "../store/selectors";
import { UserType } from "../models/ModelTypes";
import { useSelector } from "react-redux";
import "./Teachers.css";
import { useAppDispatch, useAppSelector } from "../utilities/hooks";
import { updateThunk } from "../store/thunks";
type SummaryType = { incomplete: number; complete: number };

const Teachers = () => {
  const [teacherList, setTeacherList] = useState<UserType[]>([]);
  const [summary, setSummary] = useState<SummaryType>({
    complete: 0,
    incomplete: 0,
  });
  const token = useAppSelector(selectPropertyValue("id_token"));
  const dispatch = useAppDispatch();
  // const db = useContext(DBContext);
  const users = useSelector(selectPropertyValue("users")) as UserType[];

  useEffect(() => {
    const sortedUsers = users
      .filter((u) => !u.isAdmin)
      .sort((a, b) =>
        a.lastName.toLowerCase() + "," + a.firstName.toLowerCase() <
        b.lastName.toLowerCase() + "," + b.firstName.toLowerCase()
          ? -1
          : 1
      );
    const summary = sortedUsers.reduce(
      (summary: SummaryType, u: UserType) => {
        summary.incomplete += !u.interventionFlaggingComplete ? 1 : 0;
        summary.complete += u.interventionFlaggingComplete ? 1 : 0;
        return summary;
      },
      { incomplete: 0, complete: 0 }
    );
    setTeacherList(sortedUsers);
    setSummary(summary);
  }, [users]);

  const toggle = (evt: React.MouseEvent<HTMLButtonElement>) => {
    const _id = evt.currentTarget.getAttribute("data-key");
    const value = evt.currentTarget.getAttribute("data-value") === "true";
    dispatch(
      updateThunk({
        collectionName: "users",
        records: [{ _id, interventionFlaggingComplete: !value }],
        deleteFirst: false,
        token,
      })
    );
  };

  const setAll = (value: boolean) => () => {
    const records = teacherList.map((t) => {
      return {
        interventionFlaggingComplete: value,
        _id: t._id,
      };
    });
    dispatch(
      updateThunk({
        collectionName: "users",
        records,
        deleteFirst: false,
        token,
      })
    );
  };

  return (
    <div className="row">
      <div className="col-md-10">
        <h5>Teacher list</h5>
        <table className="table table-sm table-bordered table-striped">
          <thead>
            <tr>
              <th className="text-start">Teacher name</th>
              <th className="text-start">Email</th>
              <th>Interventions complete?</th>
            </tr>
          </thead>
          <tbody>
            {teacherList.map((u, idx) => (
              <tr key={u._id}>
                <td className="name-text text-start">
                  {u.lastName}, {u.firstName}
                </td>
                <td className="name-text text-start">{u.email}</td>
                <td className="intervention-container">
                  {u.interventionFlaggingComplete ? (
                    <div className="intervention intervention-complete">
                      Yes
                    </div>
                  ) : (
                    <div className="intervention intervention-incomplete">
                      No
                    </div>
                  )}
                  <button
                    data-key={u._id}
                    data-value={u.interventionFlaggingComplete}
                    className="btn btn-sm btn-secondary toggle-button py-0"
                    onClick={toggle}
                  >
                    Toggle
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="col-md-2">
        <h5>Controls</h5>
        <div className="d-grid gap-2">
          <button className="btn btn-danger" onClick={setAll(false)}>
            Set all incomplete
          </button>
          <button className="btn btn-success" onClick={setAll(true)}>
            Set all completed
          </button>
        </div>
        <div className="intervention-summary">
          <div>Complete: {summary.complete}</div>
          <div>Incomplete: {summary.incomplete}</div>
        </div>
      </div>
    </div>
  );
};

export default Teachers;
