import React, { useState } from "react";
import NewGoogleLogin from "react-new-google-login";
import { CLIENT_ID } from "../constants";
import { useAppDispatch } from "../utilities/hooks";
import { loginAction } from "../store/actions";
import "./Login.css";
import { getCollectionThunk } from "../store/thunks";
import getUserInfo from "../utilities/getUserInfo";
import { API_URL } from "../constants";
import { UserType } from "../models/ModelTypes";

interface UserTypeAddIsStudent extends UserType {
  isStudent: boolean;
}

const Login = () => {
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [message, setMessage] = useState("");
  const dispatch = useAppDispatch();
  const signinCallback = (id_token: string | undefined) => {
    setIsLoggingIn(true);
    setMessage("Logging you in...");
    if (id_token && id_token.length > 0) {
      fetch(API_URL + "/authUser", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ id_token }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.error) {
            setMessage(res.message);
          } else {
            dispatch(loginAction(res.token));

            const userInfo = getUserInfo(res.token) as UserTypeAddIsStudent;
            let parms: {
              collectionName: string;
              token: string;
              filterField?: string;
              filterValue?: string;
            } = { collectionName: "students", token: res.token };
            if (userInfo.isStudent) {
              parms.filterField = "stuId";
              parms.filterValue = userInfo.email.split("@")[0];
            }
            dispatch(getCollectionThunk(parms));
            dispatch(
              getCollectionThunk({
                collectionName: "schedules",
                token: res.token,
              })
            );
            dispatch(
              getCollectionThunk({
                collectionName: "settings",
                token: res.token,
              })
            );
            if (userInfo.isStudent || userInfo.isAdmin) {
              dispatch(
                getCollectionThunk({
                  collectionName: "courses",
                  token: res.token,
                })
              );
              dispatch(
                getCollectionThunk({
                  collectionName: "enrollments",
                  token: res.token,
                })
              );
            }
            if (userInfo.isAdmin) {
              dispatch(
                getCollectionThunk({
                  collectionName: "users",
                  token: res.token,
                })
              );
            }
          }
        })
        .catch((err) => {
          setMessage("No connection to the server");
          console.log(err);
        });
    }
  };

  const errorCallback = (err: any) => console.log(err);

  if (isLoggingIn || message.length > 0) {
    return (
      <div className="login-button text-2xl">
        <div className="unable-to-login">{message}</div>
      </div>
    );
  } else {
    return (
      <div>
        <div className="login-button">
          <NewGoogleLogin
            clientId={CLIENT_ID}
            signinCallback={signinCallback}
            errorCallback={errorCallback}
            className="login-button"
          />
        </div>
        <div className="row">
          <div className="gv col-md-6 offset-3">
            <div className="gv-title">
              Marston Middle School Explorations Scheduler
            </div>
            <div className="gv-purpose">
              <span className="gv-header">Use:</span> This program is used to
              schedule students for Exploration, Intervention, and other courses
              for each 3-week cycle.
            </div>
            <div className="gv-privacy-policy">
              <span className="gv-header">Privacy policy:</span> When you log in
              the program will receive your name and email address. This
              information not shared in any way, shape, or form with anyone.
              Student emails (stu.sandi.net) are restricted and are only able to
              send and receive email with district sandi.net email addresses.
            </div>
            <div className="gv-usage">
              <span className="gv-header">Restricted access:</span> This program
              is meant for use only by the staff and students of Marston Middle
              School. Use by non-school staff and students is strictly
              prohibited.
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Login;
