import React, { useState } from "react";
import Navbar from "../../components/Navbar";
import { NavbarChoiceType } from "../../components/Navbar";
import CourseMarks from "./CourseMarks";
import "./Students.css";

const Students = () => {
  const [typeState, setTypeState] = useState<string>("yearCourses"); // or 'requests'

  const changeTypeState = (t: string) => {
    setTypeState(t);
  };

  const navbarButtons: NavbarChoiceType[] = [
    { name: "Year Courses", value: "yearCourses" },
    { name: "Requests by course", value: "requestsByCourse" },
    // { name: "Requests by student", value: "requestsByStudent" },
  ];

  return (
    <div>
      <Navbar
        choices={navbarButtons}
        selectedValue={typeState}
        onChange={changeTypeState}
        className="student-navbar"
      />
      {typeState === "yearCourses" ? (
        <CourseMarks courseType="yearCourses" />
      ) : typeState === "requestsByCourse" ? (
        <CourseMarks courseType="requests" />
      ) : (
        // ) : typeState === "requestsByStudent" ? (
        //   <StudentCourses courseType="" />
        <span>Select Year Courses or Student Requests</span>
      )}
    </div>
  );
};

export default Students;
