import React from "react";

export type NavbarChoiceType = { name: string; value: string };
export type NavbarOnChangeType = (choice: string) => void;
export type NavbarPropsType = {
  choices: NavbarChoiceType[];
  selectedValue: string;
  onChange: NavbarOnChangeType;
  className?: string;
};

const Navbar = ({
  choices,
  selectedValue,
  onChange,
  className,
}: NavbarPropsType): JSX.Element => {
  const onClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
    onChange(evt.currentTarget.getAttribute("name") ?? "");
  };

  return (
    <div className={className}>
      {choices.map((c) => (
        <button
          key={c.name}
          className={
            selectedValue === c.value ? "btn btn-primary" : "btn btn-secondary"
          }
          name={c.value}
          onClick={onClick}
        >
          {c.name}
        </button>
      ))}
    </div>
  );
};

export default Navbar;
