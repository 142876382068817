import jwtDecode from "jwt-decode";
import { RootState } from "./initState";

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.settings.value)`
export const selectPropertyValue = (prop: string) => (state: any) =>
  state[prop];

export const selectUserInfo = (state: RootState): any => {
  return state.id_token.length > 0 ? jwtDecode(state.id_token) : {};
};

export const selectEnrollmentsByCycleGrade =
  (cycle: string, gradeLevel: number) =>
  (state: RootState): any => {
    const studentLookupByGrade = state.students.reduce((obj: any, s) => {
      obj[s.stuId] = s.gradeLevel;
      return obj;
    }, {});
    const filtered = state.enrollments.filter(
      (e) =>
        e.cycleId === cycle &&
        (studentLookupByGrade[e.studentId] & gradeLevel) >= 0
    );
    return filtered.reduce((obj: any, en) => {
      obj[en.studentId] = en;
      return obj;
    }, {});
  };

export const selectEnrollmentsByCourse =
  (courseId: string) =>
  (state: RootState): any => {
    return state.enrollments.filter((e) => e.courseId === courseId);
  };
