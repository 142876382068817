import {
  CourseType,
  EnrollmentType,
  RequestType,
  ScheduleType,
  SettingsType,
  StudentType,
  UserType,
} from "../models/ModelTypes";

export interface RootState {
  isLoggedIn: boolean;
  activePage: string;
  id_token: string;
  error: object | null;
  dirty: boolean;
  schedulerState: string;
  listState: string;

  isUpdating: boolean;
  hasUpdated: boolean;
  hasUpdatedDataType: string;
  updateFlag: number;
  inProcess: number;

  settings: SettingsType | null;
  students: StudentType[];
  schedules: ScheduleType[];
  courses: CourseType[];
  users: UserType[];
  enrollments: EnrollmentType[];
  requests: RequestType[];
  gradeLevelData: any[];

  value: number;
  status: "idle" | "loading" | "failed";
  dataStatus: {
    schedules: boolean;
    settings: boolean;
    courses: boolean;
    enrollments: boolean;
    users: boolean;
    students: boolean;
    // requests: boolean;
  };
}

export const initialState: RootState = {
  isLoggedIn: false,
  id_token: "",
  activePage: "",
  error: null,
  dirty: false,
  schedulerState: "",
  listState: "",

  isUpdating: false,
  hasUpdated: false,
  hasUpdatedDataType: "",
  updateFlag: 0,
  inProcess: 0,

  settings: null,
  students: [],
  schedules: [],
  courses: [],
  users: [],
  enrollments: [],
  requests: [],
  gradeLevelData: [],

  value: 0,
  status: "idle",
  dataStatus: {
    schedules: false,
    settings: false,
    courses: false,
    enrollments: false,
    users: false,
    students: false,
    // requests: false,
  },
};
