import { clone } from "ramda";

export const randomlySelect = (
  data: any[],
  currentList: string[],
  count: number
) => {
  const randomized = data.map((f: any) => {
    f.rn = Math.random();
    return f;
  });
  randomized.sort((a: any, b: any) => a.rn - b.rn);
  let sus = clone(currentList);
  count = Math.min(count, data.length);
  while (count) {
    sus.push(randomized[count - 1]._id);
    count--;
  }
  return sus;
};
