import React from "react";
import Navbar from "../../components/Navbar";
import { useAppDispatch, useAppSelector } from "../../utilities/hooks";
import { selectPropertyValue } from "../../store/selectors";
import { setListStateAction } from "../../store/actions";
import "./Lists.css";
import Rosters from "./Rosters";
import Passes from "./Passes";
import Requests from "./Requests";

const Lists = () => {
  const listState = useAppSelector(selectPropertyValue("listState"));
  const dispatch = useAppDispatch();

  const navbarButtons = [
    { name: "Rosters", value: "rosters" },
    { name: "Passes", value: "passes" },
    { name: "Requests", value: "requests" },
  ];

  const changeListState = (choice: string) => {
    dispatch(setListStateAction(choice));
  };

  const getComponent = (listState: string) => {
    switch (listState) {
      case "rosters":
        return <Rosters />;

      case "passes":
        return <Passes />;

      case "requests":
        return <Requests />;

      default:
        return <div>Choose a pass type...</div>;
    }
  };

  return (
    <div>
      <Navbar
        choices={navbarButtons}
        selectedValue={listState}
        onChange={changeListState}
        className="scheduler-navbar"
      />
      {getComponent(listState)}
    </div>
  );
};

export default Lists;
