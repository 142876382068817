import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getCollectionAction,
  hasUpdatedAction,
  isUpdatingAction,
  refreshTokenAction,
  setDirtyAction,
  thrownErrorAction,
  triggerUpdateFlag,
} from "./actions";

export const deleteThunk = createAsyncThunk(
  "deleteThunk",
  async (
    parms: {
      collectionName: string;
      _id: string;
      token: string;
    },
    { getState, dispatch, extra }: { getState: any; dispatch: any; extra: any }
  ) => {
    let { token, collectionName, _id } = parms;
    await fetch(extra.apiUrl + "/remove", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ collectionName, token, _id }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          dispatch(thrownErrorAction(res));
        } else {
          dispatch(getCollectionThunk({ collectionName, token }));
          dispatch(triggerUpdateFlag());
        }
      });
  }
);

export const updateThunk = createAsyncThunk(
  "updateThunk",
  async (
    parms: {
      collectionName: string;
      records: any[];
      deleteFirst: boolean;
      token: string;
    },
    { getState, dispatch, extra }: { getState: any; dispatch: any; extra: any }
  ) => {
    let { token, collectionName, records, deleteFirst = false } = parms;
    if (deleteFirst) {
      await fetch(extra.apiUrl + "/remove", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ collectionName, token }),
      });
    }
    fetch(extra.apiUrl + "/update", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ collectionName, records, token }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          dispatch(thrownErrorAction(res));
        } else {
          dispatch(getCollectionThunk({ collectionName, token }));
          dispatch(triggerUpdateFlag());
        }
      });
  }
);

export const removeManyThunk = createAsyncThunk(
  "removeManyThunk",
  async (
    parms: {
      collectionName: string;
      ids: string[];
      token: string;
    },
    { getState, dispatch, extra }: { getState: any; dispatch: any; extra: any }
  ) => {
    let { token, collectionName, ids } = parms;
    fetch(extra.apiUrl + "/removeMany", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ collectionName, ids, token }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          dispatch(thrownErrorAction(res));
        } else {
          dispatch(getCollectionThunk({ collectionName, token }));
          dispatch(triggerUpdateFlag());
        }
      });
  }
);

export const getCollectionThunk = createAsyncThunk(
  "getCollectionThunk",
  async (
    parms: {
      collectionName: string;
      filterField?: string;
      filterValue?: string;
      token: string;
    },
    { getState, dispatch, extra }: { getState: any; dispatch: any; extra: any }
  ) => {
    let { collectionName, token, filterField, filterValue } = parms;
    if (!token) {
      const state = getState();
      token = state.id_token;
    }
    fetch(extra.apiUrl + "/get", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ collectionName, token, filterField, filterValue }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          dispatch(thrownErrorAction(res));
        } else {
          dispatch(isUpdatingAction(false));
          dispatch(hasUpdatedAction(true));
          dispatch(setDirtyAction(false));
          dispatch(getCollectionAction({ collectionName, items: res.items }));
        }
      });
  }
);

export const refreshTokenActionThunk = createAsyncThunk(
  "refreshTokenThunk",
  async (
    _: any,
    { getState, dispatch, extra }: { getState: any; dispatch: any; extra: any }
  ) => {
    const id_token = getState().id_token;
    fetch(extra.apiUrl + "/refreshToken", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token: id_token }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          dispatch(thrownErrorAction(res));
        } else {
          dispatch(refreshTokenAction(res.token));
        }
      });
  }
);
