import React from "react";
import { useAppSelector } from "../../utilities/hooks";
import { selectPropertyValue } from "../../store/selectors";
import {
  CourseType,
  EnrollmentType,
  StudentType,
} from "../../models/ModelTypes";
import { clone } from "ramda";
import { download } from "../../utilities/download";
import { toGradeLevel } from "../../utilities/toGradeLevel";
import "./Requests.css";

const Requests = () => {
  const courses = useAppSelector(selectPropertyValue("courses"));
  const enrollments = useAppSelector(selectPropertyValue("enrollments"));
  const students = clone(useAppSelector(selectPropertyValue("students"))).sort(
    (a: StudentType, b: StudentType) =>
      a.lastName.toLowerCase() + "," + a.firstName.toLowerCase() <
      b.lastName.toLowerCase() + "," + b.firstName.toLowerCase()
        ? -1
        : 1
  );

  const getCourse = (courseId: string) => {
    return courses.find((c: CourseType) => c._id === courseId) ?? null;
  };

  const downloadData = () => {
    const csv = getCsv();
    download(csv, "Student requests.csv", "application/octet-stream");
  };

  const requestListHtml = (courses: any, studentKey: string) => {
    return courses.map((c: any) => {
      return (
        <div className="inline-request" key={studentKey + c._id}>
          <span className={c.enrolled ? "already-taken" : "not-taken"}>
            {c.name}
          </span>
          <span> | </span>
        </div>
      );
    });
  };

  // const requestListText = (courses: any) => {
  //   return requestListArray(courses).join(" | ");
  //   // const l = courses.map((c: any) => {
  //   //   return c.name;
  //   // });
  //   // return l.join(" | ");
  // };
  //
  const requestListArray = (courses: any) => {
    return courses.map((c: any) => {
      return { name: c.name, alreadyTaken: !!c.enrolled };
    });
  };

  const getData = (isHtml: boolean): string[][] => {
    let rows: string[][] = [];
    students.forEach((s: StudentType) => {
      const courseIds = s.requests
        .split(",")
        .filter((id: string) => id.length > 0);
      const courses = courseIds.map((id: string) => {
        return {
          _id: id,
          name: getCourse(id)?.name ?? "",
          enrolled: enrollments.find(
            (e: EnrollmentType) => e.studentId === s._id && e.courseId === id
          ),
        };
      });
      courses.sort((a, b) =>
        a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
      );
      if (isHtml) {
        rows.push([
          `${s.stuId}`,
          `${s.lastName}, ${s.firstName}`,
          `${toGradeLevel(s.gradeLevel)}`,
          requestListHtml(courses, s._id),
        ]);
      } else {
        const cArr = requestListArray(courses);
        cArr.forEach((c: any) => {
          rows.push([
            `${s.stuId}`,
            `${s.lastName}, ${s.firstName}`,
            `${toGradeLevel(s.gradeLevel)}`,
            c.name,
            c.alreadyTaken,
          ]);
        });
      }
    });
    rows.sort((a: string[], b: string[]) => {
      return a[1].toLowerCase() < b[1].toLowerCase() ? -1 : 1;
    });
    return rows;
  };

  const getCsv = () => {
    let arr = [
      ["Student ID", "Name", "Grade", "Already taken", "Requested course"],
    ];
    const data = getData(false);
    arr = arr.concat(
      data.map((d: any) => [
        '"' + d[0] + '"',
        '"' + d[1] + '"',
        '"' + d[2] + '"',
        '"' + (d[4] ? "X" : "") + '"',
        '"' + d[3] + '"',
      ])
    );
    let newArr = arr.map((row: string[]) => row.join(","));
    return newArr.join("\r\n");
  };

  if (!students || !courses) return <h5>Please wait...</h5>;

  return (
    <>
      <div className="row mb-3">
        <div className="col-md-12 text-end">
          <button className="btn btn-primary btn-sm" onClick={downloadData}>
            Download
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 overflow-scroll">
          <table className="table table-condensed table-sm">
            <thead>
              <tr>
                <th style={{ width: "15%" }}>Student ID</th>
                <th style={{ width: "20%" }} className="text-start">
                  Name
                </th>
                <th style={{ width: "8%" }} className="text-center">
                  Grade
                </th>
                <th className="text-start">Requests</th>
              </tr>
            </thead>
            <tbody>
              {getData(true).map((s: string[]) => {
                return (
                  <tr
                    key={s[0]}
                    className={s[4] === "Not enrolled" ? "not-enrolled" : ""}
                  >
                    <td>{s[0]}</td>
                    <td className="text-start">{s[1]}</td>
                    <td>{s[2]}</td>
                    <td className="text-start">{s[3]}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Requests;
