import React, { useState } from "react";
import { useAppSelector } from "../../utilities/hooks";
import { selectPropertyValue } from "../../store/selectors";
import {
  CourseType,
  EnrollmentType,
  StudentType,
} from "../../models/ModelTypes";
import { clone, groupBy } from "ramda";
import { download } from "../../utilities/download";

const Rosters = () => {
  const [sortByCourse, setSortByCourse] = useState(false);
  const students = clone(useAppSelector(selectPropertyValue("students"))).sort(
    (a: StudentType, b: StudentType) =>
      a.lastName.toLowerCase() + "," + a.firstName.toLowerCase() <
      b.lastName.toLowerCase() + "," + b.firstName.toLowerCase()
        ? -1
        : 1
  );
  const enrollments = useAppSelector(selectPropertyValue("enrollments"));
  const courses = useAppSelector(selectPropertyValue("courses"));
  const settings = useAppSelector(selectPropertyValue("settings"));

  const toggleSort = () => {
    setSortByCourse((st) => !st);
  };

  const getCourseNameAndTeacher = (courseId: string, sectionId: string) => {
    const c = courses.find((c: CourseType) => c._id === courseId) ?? null;
    return c
      ? {
          name: c.name,
          section: sectionId,
          teacher: c.teacherList
            ? c.teacherList[parseInt(sectionId, 10) - 1 + ""]
            : "",
        }
      : { name: "Not enrolled", section: "", teacher: "" };
  };
  let enrollmentsByStudent: { [id: string]: EnrollmentType[] } | null = null;
  if (enrollments) {
    const _e: EnrollmentType[] = enrollments.filter(
      (e: EnrollmentType) => e.cycleId === settings.cycle
    );
    enrollmentsByStudent = groupBy((e: EnrollmentType) => e.studentId, _e);
  }

  const downloadData = () => {
    const csv = getCsv();
    download(
      csv,
      "Rosters Cycle " + settings.cycle + ".csv",
      "application/octet-stream"
    );
  };

  const getData = (): string[][] => {
    let rows = [["Student ID", "Name", "Course", "Teacher / Section"]];
    students.forEach((s: StudentType) => {
      const enrollment =
        enrollmentsByStudent && enrollmentsByStudent[s._id]
          ? enrollmentsByStudent[s._id][0]
          : null;
      const course = getCourseNameAndTeacher(
        enrollment?.courseId ?? "",
        enrollment?.sectionId ?? ""
      );
      rows.push([
        `"${s.stuId}"`,
        `"${s.lastName}, ${s.firstName}"`,
        `"${course.name}"`,
        `"${course.section} ${course.teacher}"`,
      ]);
    });
    rows.sort((a: string[], b: string[]) => {
      if (sortByCourse) {
        return a[2] < b[2] ? -1 : 1;
      } else {
        return a[1] < b[1] ? -1 : 1;
      }
    });
    return rows;
  };

  const getCsv = () => {
    let arr = [["Student ID", "Name", "Course", "Teacher / Section"]];
    arr = arr.concat(getData());
    let newArr = arr.map((row: string[]) => row.join(","));
    return newArr.join("\r\n");
  };

  const data = getData().map((r: string[]) =>
    r.map((l: string) => l.replace(/"/g, ""))
  );

  if (!enrollmentsByStudent || !settings) return <h5>Please wait...</h5>;
  return (
    <>
      <div className="row mb-3">
        <div className="col-md-6">
          <h4 className="text-start">Cycle: {settings.cycle}</h4>
        </div>
        <div className="col-md-3">
          <button className="btn btn-primary btn-sm" onClick={toggleSort}>
            Sort by {sortByCourse ? "student name" : "course"}
          </button>
        </div>
        <div className="col-md-3">
          <button className="btn btn-primary btn-sm" onClick={downloadData}>
            Download
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 overflow-scroll">
          <table className="table table-condensed table-sm">
            <thead>
              <tr>
                <th>Student ID</th>
                <th className="text-start">Name</th>
                <th className="text-start">Course</th>
                <th>Section / Teacher / Room</th>
              </tr>
            </thead>
            <tbody>
              {data.map((s: string[]) => {
                return (
                  <tr
                    key={s[0]}
                    className={s[2] === "Not enrolled" ? "not-enrolled" : ""}
                  >
                    <td>{s[0]}</td>
                    <td className="text-start">{s[1]}</td>
                    <td className="text-start">{s[2]}</td>
                    <td>{s[3]}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Rosters;
