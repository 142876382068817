export const CLIENT_ID =
  "389287346037-ugtrl97k5ecgrc2t7aiv4irnm0uafn6l.apps.googleusercontent.com";
// export const CLIENT_ID =
//   "771982816212-de97sh19s9i2r6vt2nh53sicqgsq4skc.apps.googleusercontent.com";
export const IMAGE_BUCKET =
  "https://storage.googleapis.com/exploration-v2.appspot.com";
export const NANOID_CHARSET =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

// export const API_URL = "http://localhost:5001/marston-explorations/us-central1";
// export const API_URL = "https://us-central1-marston-explorations.cloudfunctions.net";
const _API_URL = window.location.host.includes("localhost")
  ? "http://localhost:5001/marston-explorations/us-central1"
  : "https://us-central1-marston-explorations.cloudfunctions.net";
export const API_URL = _API_URL;
export const IMAGE_URL =
  "https://storage.googleapis.com/marston-explorations.appspot.com/";
export const INSTRUCTIONS_URL =
  "https://docs.google.com/document/d/1f06OBifkDQmCfol1_CrpliTniXDQiHtRRGfrPvIwev0/edit?usp=sharing";

export const CONTACT_NAME = 'Nancy Santos';
export const CONTACT_EMAIL = 'nsantos@sandi.net';
