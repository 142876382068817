import React, { useEffect, useState } from "react";
import { selectPropertyValue } from "../store/selectors";
import {
  CoursePriorities,
  CourseType,
  GradeLevels,
  StudentType,
} from "../models/ModelTypes";
import ControlButtons from "../components/ControlButtons";
import { useAppDispatch, useAppSelector } from "../utilities/hooks";
import "./Courses.css";
import { deleteThunk, updateThunk } from "../store/thunks";
import { setDirtyAction } from "../store/actions";
import { API_URL, IMAGE_URL } from "../constants";
import { customAlphabet } from "nanoid";
import { useDeepCompareEffect } from "react-use";
import { clone } from "ramda";

const Courses = () => {
  const [selectedCourseKey, setSelectedCourseKey] = useState<string>("");
  const [_id, set_id] = useState("");
  const [name, setName] = useState<string>("");
  const [abbrev, setAbbrev] = useState<string>("");
  const [sectionCount, setSectionCount] = useState(0);
  const [classSize, setClassSize] = useState(0);
  const [subject, setSubject] = useState("");
  const [gradeLevel, setGradeLevel] = useState<number>(0);
  const [teacherList, setTeacherList] = useState<string[]>(["", "", "", ""]);
  const [category, setCategory] = useState("");
  const [priority, setPriority] = useState(CoursePriorities.EXPLORATION);
  const [description, setDescription] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [enabled, setEnabled] = useState(true);

  const [categories, setCategories] = useState<Set<string>>(new Set());
  const [uploadingImage /*, setUploadImage*/] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [reloadFlag, setReloadFlag] = useState(false);
  const _courses = useAppSelector<CourseType[]>(selectPropertyValue("courses"));
  const courses = JSON.parse(JSON.stringify(_courses)) as CourseType[];
  const token = useAppSelector<string>(selectPropertyValue("id_token"));
  const dirty = useAppSelector<boolean>(selectPropertyValue("dirty"));
  const students = clone(useAppSelector(selectPropertyValue("students")));
  const dispatch = useAppDispatch();
  const sortedCourses = courses.sort((a, b) => (a.name < b.name ? -1 : 1));

  useEffect(() => {
    setIsValid(
      (category.length > 0 &&
        name.length > 0 &&
        (priority !== CoursePriorities.INTERVENTION || subject.length > 0) &&
        priority >= 0 &&
        gradeLevel > 0 &&
        !isNaN(classSize) &&
        !isNaN(sectionCount)) ??
        false
    );
  }, [
    category,
    abbrev,
    name,
    subject,
    priority,
    gradeLevel,
    sectionCount,
    classSize,
    description,
  ]);

  useDeepCompareEffect(() => {
    let cats = courses.reduce((acc, c) => {
      acc.add(c.category);
      return acc;
    }, new Set<string>());
    setCategories(cats);
  }, [courses]);

  useDeepCompareEffect(() => {
    if (selectedCourseKey.length === 0) return;
    const course = courses.find((c) => c._id === selectedCourseKey);
    if (!course) return;
    setFields(course);
  }, [selectedCourseKey, reloadFlag, courses]);

  const setFields = (course: CourseType) => {
    set_id(course._id);
    setName(course.name);
    setAbbrev(course.abbrev);
    setSectionCount(course.sectionCount);
    setPriority(course.priority);
    setClassSize(course.classSize);
    setSubject(course.subject ?? "");
    setGradeLevel(course.gradeLevel);
    setTeacherList(course.teacherList ?? ["", "", "", ""]);
    setCategory(course.category);
    setDescription(course.description);
    setImageUrl(course.imageUrl ?? "");
    setEnabled(course.enabled);
  };

  const selectCourseClick = (evt: React.MouseEvent<HTMLLIElement>) => {
    const key = evt.currentTarget.getAttribute("data-key");
    setSelectedCourseKey(key ?? "");
    const fn = document.getElementById("image") as HTMLInputElement;
    if (fn) {
      fn.value = "";
    }
    dispatch(setDirtyAction(false));
  };

  const onChange =
    (setter: React.Dispatch<React.SetStateAction<string>>) =>
    (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setter(evt.currentTarget.value);
      dispatch(setDirtyAction(true));
    };

  const onChangeEnabled =
    (setter: React.Dispatch<React.SetStateAction<boolean>>) =>
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      setter(evt.currentTarget.checked);
      dispatch(setDirtyAction(true));
    };

  const onChangeNumber =
    (setter: React.Dispatch<React.SetStateAction<number>>) =>
    (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setter(parseInt(evt.currentTarget.value, 10));
      dispatch(setDirtyAction(true));
    };

  const onChangeGradeLevel =
    (setter: React.Dispatch<React.SetStateAction<number>>) =>
    (evt: React.MouseEvent<HTMLButtonElement>) => {
      const glText = evt.currentTarget.getAttribute("data-val");
      if (!glText) return;
      const gl = gradeLevel ^ parseInt(glText, 10);
      setter(gl);
      dispatch(setDirtyAction(true));
    };

  const onChangePriority =
    (setter: React.Dispatch<React.SetStateAction<number>>) =>
    (evt: React.MouseEvent<HTMLButtonElement>) => {
      const priority = evt.currentTarget.getAttribute("data-val");
      if (!priority) return;
      setter(parseInt(priority, 10));
      if (parseInt(priority, 10) !== CoursePriorities.INTERVENTION) {
        setSubject("");
      }
      dispatch(setDirtyAction(true));
    };

  const onChangeSubject = (evt: React.MouseEvent<HTMLButtonElement>) => {
    const subject = evt.currentTarget.getAttribute("data-val");
    if (!subject) return;
    setSubject(subject);
    dispatch(setDirtyAction(true));
  };

  const onChangeTeacher =
    (setter: React.Dispatch<React.SetStateAction<string[]>>) =>
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const idx = parseInt(
        evt.currentTarget.getAttribute("data-idx") ?? "0",
        10
      );
      const val = evt.currentTarget.value;
      if (idx === null) return;
      let tl = JSON.parse(JSON.stringify(teacherList));
      tl[idx] = val;
      setter(tl);
      dispatch(setDirtyAction(true));
    };

  const newCategory = () => {
    const newCat = window.prompt("What is the new category?", "");
    if (newCat) {
      const _cats = new Set(categories);
      _cats.add(newCat);
      setCategories(_cats);
      setCategory(newCat);
    }
  };

  const onChangeCategory =
    (setter: React.Dispatch<React.SetStateAction<string>>) =>
    (evt: React.ChangeEvent<HTMLSelectElement>) => {
      const val = evt.currentTarget.value ?? "";
      setter(val);
      dispatch(setDirtyAction(true));
    };

  const onChangeImage = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (
      !evt.currentTarget ||
      !evt.currentTarget.files ||
      evt.currentTarget.files.length === 0
    )
      return;
    let file = evt.currentTarget.files[0];
    if (file) {
      const nanoid = customAlphabet("0123456789", 10);
      const filenameSuffix = nanoid();
      const formData = new FormData();
      formData.append("token", token);
      formData.append("imageFile", file);
      formData.append("filenameSuffix", filenameSuffix);
      fetch(API_URL + "/imageUpload", {
        method: "POST",
        // headers: { "Content-Type": "multipart/form-data; boundary=XXX" },
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          setImageUrl(data.filename);
          dispatch(setDirtyAction(true));
        })
        .catch((err) => console.error(err));
    }
  };

  const addCourse = () => {
    dispatch(setDirtyAction(true));
    setPriority(CoursePriorities.EXPLORATION);
    set_id("");
    setName("");
    setAbbrev("");
    setSectionCount(1);
    setClassSize(30);
    setGradeLevel(GradeLevels.SIX + GradeLevels.SEVEN + GradeLevels.EIGHT);
    setSubject("");
    setTeacherList(["", "", "", ""]);
    setCategory("");
    setDescription("");
    setImageUrl("");
    setEnabled(true);
  };

  const deleteCourse = () => {
    if (!window.confirm(`Are you sure you want to delete ${name}`)) return;
    if (!selectedCourseKey) return;
    dispatch(
      deleteThunk({
        collectionName: "courses",
        _id: selectedCourseKey,
        token,
      })
    );
  };

  const saveCourse = () => {
    const newCourse = {
      _id,
      priority,
      name,
      abbrev,
      subject,
      sectionCount,
      classSize,
      gradeLevel,
      teacherList,
      category,
      description,
      imageUrl,
      enabled,
    };
    dispatch(
      updateThunk({
        collectionName: "courses",
        records: [newCourse],
        deleteFirst: false,
        token,
      })
    );
  };

  const cancelCourse = () => {
    setReloadFlag((f) => !f);
    dispatch(setDirtyAction(false));
  };

  const catArr = Array.from(categories).sort((a, b) => (a < b ? -1 : 1));
  let courseTypes = [
    "(Year)",
    "(Grade req'd)",
    "(Intervention)",
    "(Exploration)",
  ];

  const getRequestCount = (courseId: string) =>
    students.reduce(
      (count: number, s: StudentType) =>
        s.requests.includes(courseId) ? count + 1 : count,
      0
    );

  return (
    <div className="row">
      <div className="col-md-5">
        <h5>Course list</h5>
        <h6>
          Courses in <span className="course-disabled">red</span> are disabled
          for student requests.{" "}
        </h6>
        <h6>
          <span className="badge bg-secondary">&nbsp;</span> the number of
          course sections
        </h6>
        <h6>
          <span className="badge bg-success">&nbsp;</span> the number of course
          requests.
        </h6>
        <div className="course-list-container">
          <ul className="list-group course-list">
            {sortedCourses.map((u, idx) => {
              let className = "list-group-item pointer ";
              className += u._id === selectedCourseKey ? "active " : "";
              className +=
                !u.enabled && u.priority === CoursePriorities.EXPLORATION
                  ? "course-disabled "
                  : "";
              const requestCount = getRequestCount(u._id);
              // className += u.sectionCount === 0 ? "no-sections " : "";
              return (
                <li
                  className={className}
                  key={u._id}
                  data-key={u._id}
                  onClick={selectCourseClick}
                >
                  <div className="course-name row">
                    <div className="col-md-9">{u.name}</div>
                    <div className="col-md-1 text-end">
                      {" "}
                      <span className="badge bg-secondary badge-spacer">
                        {u.sectionCount}
                      </span>
                    </div>
                    <div className="col-md-1 text-end">
                      <span className="badge bg-success badge-request-size">
                        {requestCount}
                      </span>
                    </div>
                  </div>
                  <div className="course-category">
                    {u.category} {courseTypes[u.priority]}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="col-md-7">
        <h5>Course information</h5>
        <ControlButtons
          addClick={addCourse}
          addDisabled={dirty}
          deleteClick={deleteCourse}
          deleteDisabled={dirty || !selectedCourseKey}
          saveClick={saveCourse}
          saveDisabled={!dirty || !isValid}
          cancelClick={cancelCourse}
          cancelDisabled={!dirty}
        />
        {selectedCourseKey.length > 0 || dirty ? (
          <>
            <div className="mb-3 row">
              <label
                htmlFor="name"
                className="col-sm-4 col-form-label text-end"
              >
                Name:
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="Course name"
                  onChange={onChange(setName)}
                  value={name}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label
                htmlFor="category"
                className="col-sm-4 col-form-label text-end"
              >
                Category:
              </label>
              <div className="col-sm-6">
                <select
                  className="form-select form-control"
                  id="category"
                  onChange={onChangeCategory(setCategory)}
                  value={category}
                >
                  <option value="" key="@@empty">
                    Select a category...
                  </option>
                  {catArr.map((c) => (
                    <option value={c} key={c}>
                      {c}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-2">
                <button className="btn btn-primary" onClick={newCategory}>
                  New
                </button>
              </div>
            </div>

            <div className="mb-3 row">
              <label
                htmlFor="description"
                className="col-sm-4 col-form-label text-end"
              >
                Description:
              </label>
              <div className="col-sm-8">
                <textarea
                  className="form-control"
                  id="description"
                  name="description"
                  rows={5}
                  onChange={onChange(setDescription)}
                  value={description}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label
                htmlFor="priority"
                className="col-sm-4 col-form-label text-end"
                title="Roll over the buttons to see their descriptions"
              >
                Course type:
              </label>
              <div className="form-check col-sm-8 text-start">
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    onClick={onChangePriority(setPriority)}
                    data-val={CoursePriorities.YEAR_COURSE}
                    title="Students are enrolled in the class all year"
                    className={
                      priority === CoursePriorities.YEAR_COURSE
                        ? "btn btn-primary"
                        : "btn btn-secondary"
                    }
                  >
                    Year Elec
                  </button>
                  <button
                    type="button"
                    onClick={onChangePriority(setPriority)}
                    data-val={CoursePriorities.GRADE_LEVEL}
                    title="Required course for a single grade -- students take the course only once during the year"
                    className={
                      priority === CoursePriorities.GRADE_LEVEL
                        ? "btn btn-primary"
                        : "btn btn-secondary"
                    }
                  >
                    Grade
                  </button>
                  <button
                    type="button"
                    onClick={onChangePriority(setPriority)}
                    data-val={CoursePriorities.INTERVENTION}
                    title="Intervention course in Math, Sci, English, History, Learning Upgrade, Study Hall"
                    className={
                      priority === CoursePriorities.INTERVENTION
                        ? "btn btn-primary"
                        : "btn btn-secondary"
                    }
                  >
                    Intervention
                  </button>
                  <button
                    type="button"
                    onClick={onChangePriority(setPriority)}
                    data-val={CoursePriorities.EXPLORATION}
                    title="Exploration course"
                    className={
                      priority === CoursePriorities.EXPLORATION
                        ? "btn btn-primary"
                        : "btn btn-secondary"
                    }
                  >
                    Exploration
                  </button>
                </div>{" "}
              </div>
            </div>
            {priority === CoursePriorities.INTERVENTION ? (
              <div className="mb-3 row">
                <label
                  htmlFor="subject"
                  className="col-sm-4 col-form-label text-end"
                >
                  Intervention subject:
                </label>
                <div className="form-check col-sm-8 text-start">
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Basic example"
                  >
                    <button
                      type="button"
                      onClick={onChangeSubject}
                      data-val="eng"
                      className={
                        subject === "eng"
                          ? "btn btn-primary"
                          : "btn btn-secondary"
                      }
                    >
                      Eng
                    </button>
                    <button
                      type="button"
                      onClick={onChangeSubject}
                      data-val="math"
                      className={
                        subject === "math"
                          ? "btn btn-primary"
                          : "btn btn-secondary"
                      }
                    >
                      Math
                    </button>
                    <button
                      type="button"
                      onClick={onChangeSubject}
                      data-val="sci"
                      className={
                        subject === "sci"
                          ? "btn btn-primary"
                          : "btn btn-secondary"
                      }
                    >
                      Sci
                    </button>
                    <button
                      type="button"
                      onClick={onChangeSubject}
                      data-val="sh"
                      className={
                        subject === "sh"
                          ? "btn btn-primary"
                          : "btn btn-secondary"
                      }
                    >
                      StHall
                    </button>
                    <button
                      type="button"
                      onClick={onChangeSubject}
                      data-val="lu"
                      className={
                        subject === "lu"
                          ? "btn btn-primary"
                          : "btn btn-secondary"
                      }
                    >
                      LrnUpg
                    </button>
                  </div>{" "}
                </div>
              </div>
            ) : null}
            {priority !== CoursePriorities.EXPLORATION ? (
              <div className="mb-3 row">
                <label
                  htmlFor="gradeLevel"
                  className="col-sm-4 col-form-label text-end"
                >
                  Grade:
                </label>
                <div className="form-check col-sm-8 text-start">
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Basic example"
                  >
                    <button
                      type="button"
                      onClick={onChangeGradeLevel(setGradeLevel)}
                      data-val={GradeLevels.SIX}
                      className={
                        ((gradeLevel ?? 0) & GradeLevels.SIX) > 0
                          ? "btn btn-primary"
                          : "btn btn-secondary"
                      }
                    >
                      6th
                    </button>
                    <button
                      type="button"
                      onClick={onChangeGradeLevel(setGradeLevel)}
                      data-val={GradeLevels.SEVEN}
                      className={
                        ((gradeLevel ?? 0) & GradeLevels.SEVEN) > 0
                          ? "btn btn-primary"
                          : "btn btn-secondary"
                      }
                    >
                      7th
                    </button>
                    <button
                      type="button"
                      onClick={onChangeGradeLevel(setGradeLevel)}
                      data-val={GradeLevels.EIGHT}
                      className={
                        ((gradeLevel ?? 0) & GradeLevels.EIGHT) > 0
                          ? "btn btn-primary"
                          : "btn btn-secondary"
                      }
                    >
                      8th
                    </button>
                  </div>{" "}
                </div>
              </div>
            ) : null}
            {priority === CoursePriorities.EXPLORATION ? (
              <div className="mb-3 row">
                <label
                  htmlFor="enabled"
                  className="col-sm-4 col-form-label form-check-label text-end"
                >
                  Enabled:
                </label>
                <div className="col-sm-1">
                  <input
                    type="checkbox"
                    id="enabled"
                    className="form-check-input checkbox-adjust-position"
                    name="enabled"
                    onChange={onChangeEnabled(setEnabled)}
                    checked={enabled ?? false}
                  />
                </div>
              </div>
            ) : null}
            <div className="mb-3 row">
              <label
                htmlFor="sectionCount"
                className="col-sm-4 col-form-label text-end"
              >
                Section count:
              </label>
              <div className="col-sm-8">
                <input
                  type="number"
                  className="form-control"
                  id="sectionCount"
                  name="sectionCount"
                  placeholder="Section count"
                  onChange={onChangeNumber(setSectionCount)}
                  value={isNaN(sectionCount) ? "" : sectionCount}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label
                htmlFor="classSize"
                className="col-sm-4 col-form-label text-end"
              >
                Class size:
              </label>
              <div className="col-sm-8">
                <input
                  type="number"
                  className="form-control"
                  id="classSize"
                  name="classSize"
                  placeholder="Class size"
                  onChange={onChangeNumber(setClassSize)}
                  value={isNaN(classSize) ? "" : classSize}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label
                htmlFor="teacher0"
                className="col-sm-4 col-form-label text-end"
              >
                Teachers:
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  id="teacher0"
                  data-idx="0"
                  name="teacher0"
                  placeholder="Teacher name 1"
                  onChange={onChangeTeacher(setTeacherList)}
                  value={teacherList[0]}
                />
                <input
                  type="text"
                  className="form-control mt-2"
                  id="teacher1"
                  data-idx="1"
                  name="teacher1"
                  placeholder="Teacher name 2"
                  onChange={onChangeTeacher(setTeacherList)}
                  value={teacherList[1]}
                />
                <input
                  type="text"
                  className="form-control mt-2"
                  id="teacher2"
                  data-idx="2"
                  name="teacher2"
                  placeholder="Teacher name 3"
                  onChange={onChangeTeacher(setTeacherList)}
                  value={teacherList[2]}
                />
                <input
                  type="text"
                  className="form-control mt-2"
                  id="teacher3"
                  data-idx="3"
                  name="teacher3"
                  placeholder="Teacher name 4"
                  onChange={onChangeTeacher(setTeacherList)}
                  value={teacherList[3]}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label
                htmlFor="image"
                className="col-sm-4 col-form-label text-end"
              >
                Picture:
              </label>
              <div className="col-sm-8">
                <div>
                  <input
                    type="file"
                    className="form-control"
                    id="image"
                    name="image"
                    accept=".png,.gif,.jpg,.jpeg,.bmp"
                    onChange={onChangeImage}
                  />
                </div>
                <div className="col-sm-8">
                  {!imageUrl || imageUrl.length === 0 ? (
                    <h6>No course image</h6>
                  ) : uploadingImage ? (
                    <h6>Processing image</h6>
                  ) : (
                    <img
                      src={IMAGE_URL + imageUrl}
                      alt="Course icon"
                      className="class-image"
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Courses;
