import React from "react";
import { selectPropertyValue } from "../../store/selectors";
import { StudentType } from "../../models/ModelTypes";
import { useAppSelector } from "../../utilities/hooks";
import { toGradeLevel } from "../../utilities/toGradeLevel";
import "./Scheduler.css";

type SummaryType = {
  "6": { eng: number; math: number; sci: number; sh: number; lu: number };
  "7": { eng: number; math: number; sci: number; sh: number; lu: number };
  "8": { eng: number; math: number; sci: number; sh: number; lu: number };
  Unk: number;
};

const Counts = () => {
  const students = useAppSelector(selectPropertyValue("students"));
  const interventionList: ["eng", "math", "sci", "sh", "lu"] = [
    "eng",
    "math",
    "sci",
    "sh",
    "lu",
  ];
  const summary = students.reduce(
    (summary: SummaryType, student: StudentType) => {
      const gl = toGradeLevel(student.gradeLevel);
      if (gl === "Unk") {
        summary.Unk++;
      } else {
        interventionList.forEach((i) => {
          if (student[i]) {
            summary[gl][i]++;
          }
        });
      }
      return summary;
    },
    {
      "6": { eng: 0, math: 0, sci: 0, sh: 0, lu: 0 },
      "7": { eng: 0, math: 0, sci: 0, sh: 0, lu: 0 },
      "8": { eng: 0, math: 0, sci: 0, sh: 0, lu: 0 },
      Unk: 0,
    }
  );
  console.log(summary);

  return (
    <div className="row">
      <div className="col-md-12">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th></th>
              <th colSpan={5}>Intervention student counts</th>
            </tr>
            <tr>
              <th></th>
              <th className="intervention-count">English</th>
              <th className="intervention-count">Math</th>
              <th className="intervention-count">Science</th>
              <th className="intervention-count">Study Hall</th>
              <th className="intervention-count">Learning upgrade</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="col-header">6th</td>
              {interventionList.map((i) => (
                <td key={"gr6" + i}>{summary["6"][i]}</td>
              ))}
            </tr>
            <tr>
              <td className="col-header">7th</td>
              {interventionList.map((i) => (
                <td key={"gr7" + i}>{summary["7"][i]}</td>
              ))}
            </tr>
            <tr>
              <td className="col-header">8th</td>
              {interventionList.map((i) => (
                <td key={"gr8" + i}>{summary["8"][i]}</td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Counts;
