import React from "react";
import "./Switch.css";

export type SwitchOnChangeType = (val: boolean) => void;

const Switch = ({
  value,
  trueClass,
  falseClass,
  trueComponent,
  falseComponent,
  onChange,
  containerClass,
  className,
}: {
  value: boolean;
  trueClass?: string;
  falseClass?: string;
  trueComponent: JSX.Element;
  falseComponent: JSX.Element;
  onChange: SwitchOnChangeType;
  containerClass?: string;
  className?: string;
}) => {
  const onSwitchChange = () => {
    onChange(!value);
  };

  return (
    <div className={containerClass}>
      <button
        className={
          className + " switch-class mt-1 " + (value ? trueClass : falseClass)
        }
        onClick={onSwitchChange}
      >
        {value ? trueComponent : falseComponent}
      </button>
    </div>
  );
};

export default Switch;
