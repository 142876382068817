import React from "react";
import "./Splash.css";

const Splash = (props: any) => {
  const { dataStatus } = props;
  let loadingClass = "loading top-separator";
  let loadedClass = "loaded top-separator";
  return (
    <div className="row header loader top-separator">
      <div className="col-md-6 offset-md-3 top-separator">
        <h3>Loading Scheduler information...</h3>
        <h4 className={dataStatus.students ? loadedClass : loadingClass}>
          Student information
          {dataStatus.students ? "...loaded" : ""}
        </h4>
        <h4 className={dataStatus.schedules ? loadedClass : loadingClass}>
          Student schedules
          {dataStatus.schedules ? "...loaded" : ""}
        </h4>
        <h4 className={dataStatus.settings ? loadedClass : loadingClass}>
          Program settings
          {dataStatus.settings ? "...loaded" : ""}
        </h4>
        <h4 className={dataStatus.courses ? loadedClass : loadingClass}>
          Courses
          {dataStatus.courses ? "...loaded" : ""}
        </h4>
        <h4 className={dataStatus.enrollments ? loadedClass : loadingClass}>
          Enrollments
          {dataStatus.enrollments ? "...loaded" : ""}
        </h4>
        <h4 className={dataStatus.users ? loadedClass : loadingClass}>
          Users
          {dataStatus.users ? "...loaded" : ""}
        </h4>
      </div>
    </div>
  );
};

export default Splash;
