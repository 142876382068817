import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectPropertyValue } from "../../store/selectors";
import { CoursePriorities, CourseType } from "../../models/ModelTypes";

interface PriorityCourseListProps {
  title: string;
  selectedCourseId: string;
  onCourseSelected: (key: string) => void;
  priority: CoursePriorities;
}

const PriorityCourseList = ({
  title,
  selectedCourseId,
  onCourseSelected,
  priority,
}: PriorityCourseListProps) => {
  const [courseList, setCourseList] = useState<CourseType[]>([]);
  const courses = useSelector(selectPropertyValue("courses")) as CourseType[];

  useEffect(() => {
    const gradeLevel = courses
      .filter((c) => c.priority === priority && c.sectionCount > 0)
      .sort((a, b) => (a.name < b.name ? -1 : 1));
    setCourseList(gradeLevel);
  }, [courses, priority]);

  const selectCourse = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    onCourseSelected(evt.currentTarget.value);
  };

  return (
    <select
      className="form-select"
      onChange={selectCourse}
      value={selectedCourseId}
    >
      <option value="" key="__blank">
        Select a course...
      </option>
      {courseList.map((c) => (
        <option key={c._id} value={c._id}>
          {c.name}
        </option>
      ))}
    </select>
  );
};

export default PriorityCourseList;
