import React, { useEffect, useState } from "react";
import { selectPropertyValue } from "../../store/selectors";
import {
  CoursePriorities,
  CourseType,
  GradeLevels,
  StudentType,
} from "../../models/ModelTypes";
import "./Students.css";
import { useAppDispatch, useAppSelector } from "../../utilities/hooks";
import { updateThunk } from "../../store/thunks";

const CourseMarks = (props: { courseType: string }) => {
  const [studentList, setStudentList] = useState<StudentType[]>([]);
  const [studentFilter, setStudentFilter] = useState<string>("");
  const [selectedCourseKey, setSelectedCourseKey] = useState<string>("");
  const [sortedCourses, setSortedCourses] = useState<CourseType[]>([]);
  const [courseFilter, setCourseFilter] = useState<string>("");
  const [gradeLevelFilter, setGradeLevelFilter] = useState<number>(0);
  const { courseType } = props;

  const students = useAppSelector<StudentType[]>(
    selectPropertyValue("students")
  );
  const courses = useAppSelector<CourseType[]>(selectPropertyValue("courses"));
  const token = useAppSelector<string>(selectPropertyValue("id_token"));
  const dispatch = useAppDispatch();

  const getCheckMark = (flag: boolean) => (flag ? <span>&#10003;</span> : "");

  // Filter and sort students for display
  useEffect(() => {
    const sortedStudents = students
      .filter((s) => {
        if (gradeLevelFilter === 0) return s;
        let gradeFlag = 0;
        if ((s.gradeLevel & GradeLevels.SIX) > 0) gradeFlag = GradeLevels.SIX;
        if ((s.gradeLevel & GradeLevels.SEVEN) > 0)
          gradeFlag = GradeLevels.SEVEN;
        if ((s.gradeLevel & GradeLevels.EIGHT) > 0)
          gradeFlag = GradeLevels.EIGHT;
        return (gradeLevelFilter & gradeFlag) > 0;
      })
      .filter((s, idx) => {
        return (
          studentFilter.length === 0 ||
          s.stuId.toLowerCase().indexOf(studentFilter.toLowerCase()) +
            s.lastName.toLowerCase().indexOf(studentFilter.toLowerCase()) +
            s.firstName.toLowerCase().indexOf(studentFilter.toLowerCase()) >
            -3
        );
      })
      .sort((a, b) => {
        return a.lastName.toLowerCase() + "," + a.firstName.toLowerCase() <
          b.lastName.toLowerCase() + "," + b.firstName.toLowerCase()
          ? -1
          : 1;
      });
    setStudentList(sortedStudents);
  }, [students, studentFilter, gradeLevelFilter]);

  useEffect(() => {
    // const courseValues = Object.values(courses) as CourseType[];
    const sortedCourses = courses
      .filter((c) => {
        if (courseType === "yearCourses") {
          return (
            c.priority === CoursePriorities.YEAR_COURSE ||
            c.priority === CoursePriorities.GRADE_LEVEL
          );
        } else if (courseType === "requests") {
          return (
            c.priority === CoursePriorities.EXPLORATION && c.sectionCount > 0
          );
        } else {
          return true;
        }
      })
      .filter(
        (c) =>
          courseFilter.length === 0 ||
          c.name.toLowerCase().indexOf(courseFilter.toLowerCase()) >= 0
      )
      .sort((a, b) => (a.name < b.name ? -1 : 1));
    setSortedCourses(sortedCourses);
  }, [courses, courseFilter, courseType]);

  const onStudentFilterChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setStudentFilter(evt.currentTarget.value);
  };

  const onCourseFilterChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setCourseFilter(evt.currentTarget.value);
  };

  const selectCourseKey = (evt: React.MouseEvent<HTMLTableRowElement>) => {
    setSelectedCourseKey(evt.currentTarget.getAttribute("data-key") ?? "");
  };

  const onClickStudent = (evt: React.MouseEvent<HTMLTableRowElement>) => {
    if (!selectedCourseKey) return;
    evt.currentTarget.classList.add("in-process-student");
    const _id = evt.currentTarget.getAttribute("data-key");
    const thisStudent = students.find((s) => s._id === _id);
    if (!_id || !thisStudent) return;
    let crsList =
      courseType === "yearCourses"
        ? thisStudent.nonRequests
        : thisStudent.requests;
    let crsArray = crsList.split(",");
    const idx = crsArray.indexOf(selectedCourseKey);
    if (idx >= 0) {
      crsArray.splice(idx, 1);
    } else {
      crsArray.push(selectedCourseKey);
    }
    crsArray = crsArray.filter((c) => c !== "");
    dispatch(
      updateThunk({
        collectionName: "students",
        records:
          courseType === "yearCourses"
            ? [{ _id, nonRequests: crsArray.join(",") }]
            : [{ _id, requests: crsArray.join(",") }],
        deleteFirst: false,
        token,
      })
    );
  };

  const onGradeButtonClick =
    (flag: number) => (evt: React.MouseEvent<HTMLButtonElement>) => {
      if (flag === GradeLevels.NONE) {
        setGradeLevelFilter(GradeLevels.NONE);
      } else {
        setGradeLevelFilter((val) => val ^ flag);
      }
    };

  const getGradeLevel = (gradeFlag: number) => {
    if ((gradeFlag & GradeLevels.SIX) > 0) {
      return "6";
    } else if ((gradeFlag & GradeLevels.SEVEN) > 0) {
      return "7";
    } else if ((gradeFlag & GradeLevels.EIGHT) > 0) {
      return "8";
    }
  };

  return (
    <>
      <div className="row spacer">
        <label
          htmlFor="courseListFilter"
          className="col-sm-2 col-form-label text-end"
        >
          Course filter:
        </label>
        <div className="col-sm-2">
          <input
            className="form-control"
            id="courseListFilter"
            onChange={onCourseFilterChange}
          />
        </div>
        <label
          htmlFor="studentList"
          className="col-sm-2 col-form-label text-end"
        >
          Student filter:
        </label>
        <div className="col-sm-2">
          <input
            className="form-control"
            id="studentList"
            onChange={onStudentFilterChange}
          />
        </div>
        <div className="col-sm-4">
          <button
            onClick={onGradeButtonClick(GradeLevels.NONE)}
            className={
              !gradeLevelFilter
                ? "btn btn-sm btn-success wider"
                : "btn btn-sm btn-secondary wider"
            }
          >
            All
          </button>
          <button
            onClick={onGradeButtonClick(GradeLevels.SIX)}
            className={
              (gradeLevelFilter & GradeLevels.SIX) > 0
                ? "btn btn-sm btn-success wider"
                : "btn btn-sm btn-secondary wider"
            }
          >
            6th
          </button>
          <button
            onClick={onGradeButtonClick(GradeLevels.SEVEN)}
            className={
              (gradeLevelFilter & GradeLevels.SEVEN) > 0
                ? "btn btn-sm btn-success wider"
                : "btn btn-sm btn-secondary wider"
            }
          >
            7th
          </button>
          <button
            onClick={onGradeButtonClick(GradeLevels.EIGHT)}
            className={
              (gradeLevelFilter & GradeLevels.EIGHT) > 0
                ? "btn btn-sm btn-success wider"
                : "btn btn-sm btn-secondary wider"
            }
          >
            8th
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 intervention-scroller">
          <table className="table table-sm table-bordered table-bordered-heavy">
            <thead>
              <tr>
                <th className="text-center">
                  {courseType === "yearCourses" ? (
                    "Year-Long Req'd and Elective Course List"
                  ) : (
                    <div>
                      <span>Exploration Course List </span>
                      <span className="small-header">
                        (section count &gt; 0)
                      </span>
                    </div>
                  )}
                </th>
              </tr>
            </thead>
            <tbody className="intervention-table">
              {sortedCourses.map((u, idx) => (
                <tr
                  key={u._id}
                  data-key={u._id}
                  onClick={selectCourseKey}
                  className={
                    u._id === selectedCourseKey
                      ? "active-course pointer"
                      : "pointer"
                  }
                >
                  <td className="text-start">{u.name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="col-md-8 intervention-scroller">
          <table className="table table-sm table-bordered table-bordered-heavy">
            <thead>
              <tr className="student-enroll-list">
                <th className="text-center student-enroll-list-id">
                  Student ID
                </th>
                <th className="text-center student-enroll-list-grade">Grade</th>
                <th className="text-start">
                  Student name (
                  {
                    studentList.filter((s: StudentType) => {
                      const crsArray =
                        courseType === "yearCourses"
                          ? s.nonRequests
                          : s.requests;
                      return crsArray.indexOf(selectedCourseKey) >= 0;
                    }).length
                  }
                  )
                </th>
                <th className="text-center student-enroll-list-intervention">
                  M
                </th>
                <th className="text-center student-enroll-list-intervention">
                  E
                </th>
                <th className="text-center student-enroll-list-intervention">
                  S
                </th>
                <th className="text-center student-enroll-list-intervention">
                  SH
                </th>
                <th className="text-center student-enroll-list-intervention">
                  LU
                </th>
              </tr>
            </thead>
            <tbody className="intervention-table">
              {studentList.map((u, idx) => {
                const crsArray =
                  courseType === "yearCourses" ? u.nonRequests : u.requests;
                return (
                  <tr
                    key={u._id}
                    data-key={u._id}
                    onClick={onClickStudent}
                    className={
                      selectedCourseKey !== "" &&
                      crsArray.indexOf(selectedCourseKey) >= 0
                        ? "pointer enrolled"
                        : "pointer not-enrolled"
                    }
                  >
                    <td>{u.stuId}</td>
                    <td>{getGradeLevel(u.gradeLevel)}</td>
                    <td className="name-text text-start">
                      {u.lastName}, {u.firstName}
                    </td>
                    <td
                      className={
                        u.math ? "intervention-on" : "intervention-off"
                      }
                    >
                      {getCheckMark(u.math)}
                    </td>
                    <td
                      className={u.eng ? "intervention-on" : "intervention-off"}
                    >
                      {getCheckMark(u.eng)}
                    </td>
                    <td
                      className={u.sci ? "intervention-on" : "intervention-off"}
                    >
                      {getCheckMark(u.sci)}
                    </td>
                    <td
                      className={u.sh ? "intervention-on" : "intervention-off"}
                    >
                      {getCheckMark(u.sh)}
                    </td>
                    <td
                      className={u.lu ? "intervention-on" : "intervention-off"}
                    >
                      {getCheckMark(u.lu)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default CourseMarks;
