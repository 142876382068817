import React, { ChangeEvent, useEffect, useState } from "react";

import { selectPropertyValue } from "../store/selectors";
import "./Settings.css";
import Switch from "../components/Switch";
import { useAppDispatch, useAppSelector } from "../utilities/hooks";
import { removeManyThunk, updateThunk } from "../store/thunks";
import { EnrollmentType, StudentType } from "../models/ModelTypes";
import { setDirtyAction } from "../store/actions";

export function Settings() {
  const [cycle, setCycle] = useState<string>("1");
  const [minimumStudentRequests, setMinimumStudentRequests] =
    useState<string>("6");
  const [enableStudentChoices, setEnableStudentChoices] = useState(false);

  const settings = useAppSelector(selectPropertyValue("settings"));
  const students = useAppSelector(
    selectPropertyValue("students")
  ) as StudentType[];
  const enrollments = useAppSelector(
    selectPropertyValue("enrollments")
  ) as EnrollmentType[];
  const token = useAppSelector(selectPropertyValue("id_token"));
  const dirty = useAppSelector(selectPropertyValue("dirty"));
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!settings) return;
    setCycle(settings.cycle);
    setMinimumStudentRequests(settings.minimumStudentRequests);
    setEnableStudentChoices(settings.enableStudentChoices);
  }, [settings]);

  const onChange =
    (setter: React.Dispatch<React.SetStateAction<string>>) =>
    (evt: ChangeEvent<HTMLInputElement>) => {
      setter(evt.currentTarget.value);
      dispatch(setDirtyAction(true));
    };

  const onCheck = (val: boolean) => {
    setEnableStudentChoices(val);
    dispatch(setDirtyAction(true));
  };

  const save = () => {
    const newSettings = {
      _id: settings._id,
      cycle,
      enableStudentChoices,
      minimumStudentRequests,
    };
    dispatch(
      updateThunk({
        collectionName: "settings",
        records: [newSettings],
        deleteFirst: false,
        token,
      })
    );
  };

  const cancel = () => {
    setCycle(settings.cycle);
    setMinimumStudentRequests(settings.minimumStudentRequests);
    setEnableStudentChoices(settings.enableStudentChoices);
    dispatch(setDirtyAction(false));
  };

  const clearInterventions = () => {
    if (!window.confirm("Are you sure? This cannot be undone")) return;
    const records = students.map((s) => {
      return {
        _id: s._id,
        math: false,
        eng: false,
        sci: false,
        sh: false,
        lu: false,
      };
    });
    dispatch(
      updateThunk({
        collectionName: "students",
        records,
        deleteFirst: false,
        token,
      })
    );
  };

  const clearEnrollments = () => {
    if (!window.confirm("Are you sure?")) {
      return;
    }
    const toDelete = enrollments
      .filter((e: EnrollmentType) => e.cycleId === settings.cycle)
      .map((e: EnrollmentType) => e._id);
    dispatch(
      removeManyThunk({ collectionName: "enrollments", ids: toDelete, token })
    );
  };

  const clearAllEnrollments = () => {
    dispatch(
      updateThunk({
        collectionName: "enrollments",
        records: [],
        deleteFirst: true,
        token,
      })
    );
  };

  const clearAllStudents = () => {
    dispatch(
      updateThunk({
        collectionName: "students",
        records: [],
        deleteFirst: true,
        token,
      })
    );
  };

  const clearAllSchedules = () => {
    dispatch(
      updateThunk({
        collectionName: "schedules",
        records: [],
        deleteFirst: true,
        token,
      })
    );
  };

  const clearRequests = () => {
    if (!window.confirm("Are you sure?")) {
      return;
    }
    const updatedStudents = students.map((s: StudentType) => {
      const newS = {
        requests: "",
        _id: s._id,
      };
      return newS;
    });
    dispatch(
      updateThunk({
        collectionName: "students",
        records: updatedStudents,
        deleteFirst: false,
        token,
      })
    );
  };

  const setDefaultSettings = () => {
    dispatch(
      updateThunk({
        collectionName: "settings",
        records: [
          {
            _id: "0",
            cycle: "1",
            enableStudentChoices: true,
            minimumStudentRequests: "6",
          },
        ],
        deleteFirst: false,
        token,
      })
    );
  };

  const prepForNewYear = () => {
    if (!window.confirm("Are you sure?")) {
      return;
    }
    setCycle("1");
    clearRequests();
    clearInterventions();
    clearAllEnrollments();
    clearAllStudents();
    clearAllSchedules();
    setDefaultSettings();
  };

  return (
    <div className="row text-end">
      <div className="col-md-5">
        <div className="mb-3 row">
          <label htmlFor="cycle" className="col-sm-6 col-form-label">
            Current cycle:
          </label>
          <div className="col-sm-4">
            <input
              type="number"
              className="form-control"
              id="cycle"
              min={1}
              onChange={onChange(setCycle)}
              value={cycle}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="minimumStudentRequests"
            className="col-sm-6 col-form-label"
          >
            Min student requests:
          </label>
          <div className="col-sm-4">
            <input
              type="number"
              className="form-control"
              id="minimumStudentRequests"
              onChange={onChange(setMinimumStudentRequests)}
              value={minimumStudentRequests}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="enableStudentChoices"
            className="col-sm-6 mt-1 form-check-label"
          >
            Enable student choices:
          </label>
          <div className="col-sm-6">
            <Switch
              value={enableStudentChoices}
              containerClass="text-start"
              className=""
              trueClass="true-button"
              falseClass="false-button"
              trueComponent={<span className="true-button-text">Yes</span>}
              falseComponent={<span className="false-button-text">No</span>}
              onChange={onCheck}
            />
          </div>
        </div>
      </div>
      <div className="col-md-3 text-start">
        <button className="btn btn-primary" disabled={!dirty} onClick={save}>
          Save
        </button>
        <button className="btn btn-primary" disabled={!dirty} onClick={cancel}>
          Cancel
        </button>
      </div>
      <div className="col-md-4 left-divider">
        <div className="settings-divider">
          <h6 className="text-center lh-1">
            Clear the selected interventions for all students. You can't undo
            this so be careful!
          </h6>
          <button
            className="btn btn-danger mb-3 settings-button"
            onClick={clearInterventions}
          >
            Clear all student interventions!
          </button>
        </div>
        <div className="settings-divider">
          <h6 className="text-center lh-1">
            Clear all existing enrollments and reset student requests for cycle
            {` ${cycle} `} by clicking the button below. You can't undo this, so
            be careful.
          </h6>
          <button
            className="btn btn-danger mb-3 settings-button"
            onClick={clearEnrollments}
          >
            Clear all enrollments for current cycle!
          </button>
        </div>
        <div className="settings-divider">
          <h6 className="text-center lh-1">
            Clear all of the students' course requests. You can't undo this so
            be careful!
          </h6>
          <button
            className="btn btn-danger mb-3 settings-button"
            onClick={clearRequests}
          >
            Clear all student requests!
          </button>
        </div>
        <div className="settings-divider">
          <h6 className="text-center lh-1">
            Set up system for beginning of the year by clicking the button
            below. You can't undo this, so be careful.
          </h6>
          <button
            className="btn btn-danger mb-3 settings-button"
            onClick={prepForNewYear}
          >
            Prepare for a new school year!
          </button>
        </div>
      </div>
    </div>
  );
}
