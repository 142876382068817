import React from "react";
import "./ControlButtons.css";

const ControlButtons = (props: any) => {
  return (
    <div className="row">
      <div className="col-md-6 text-start">
        <button
          className="btn btn-primary control-button"
          onClick={props.addClick}
          disabled={props.addDisabled}
        >
          Add
        </button>
        <button
          className="btn btn-primary control-button"
          onClick={props.deleteClick}
          disabled={props.deleteDisabled}
        >
          Delete
        </button>
      </div>
      <div className="col-md-6 text-end">
        <button
          className="btn btn-primary control-button"
          onClick={props.saveClick}
          disabled={props.saveDisabled}
        >
          Save
        </button>
        <button
          className="btn btn-primary control-button"
          onClick={props.cancelClick}
          disabled={props.cancelDisabled}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ControlButtons;
