const isCsvValid = (
  data: string[][],
  regex: RegExp[]
): {
  isValid: boolean;
  errorInLine: number;
  errorInField: number;
  errorInLength: boolean;
} => {
  let errorInLine = -1;
  let errorInField = -1;
  if (regex.length !== data[0].length) {
    return { isValid: false, errorInLine, errorInField, errorInLength: true };
  } else {
    data = data.filter((line) => line.length > 1);
    let _data = data.map((line, idx) => {
      for (let i = 0; i < line.length; i++) {
        if (!line[i].match(regex[i])) {
          errorInLine = idx;
          errorInField = i;
          return false;
        }
      }
      return true;
    });
    return {
      isValid: _data.every((line) => line),
      errorInLine,
      errorInField,
      errorInLength: false,
    };
  }
};

export default isCsvValid;
