import React from "react";
import pkg from "../../package.json";
import "./Footer.css";
import { INSTRUCTIONS_URL } from "../constants";

const Footer = () => {
  return (
    <div className="footer">
      Version {pkg.version}
      <br />
      Copyright {pkg.copyright}
      <br />
      <a
        href={INSTRUCTIONS_URL}
        className="text-blue-500 underline"
        target="_blank"
        rel="noreferrer"
      >
        Instructions
      </a>
    </div>
  );
};

export default Footer;
