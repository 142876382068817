export enum CoursePriorities {
  YEAR_COURSE,
  GRADE_LEVEL,
  INTERVENTION,
  EXPLORATION,
}

export enum Roles {
  student,
  teacher,
  admin,
}

export enum GradeLevels {
  NONE = 0x0,
  SIX = 0x1,
  SEVEN = 0x2,
  EIGHT = 0x4,
}

export interface CourseType {
  _id: string;
  priority: CoursePriorities;
  name: string;
  abbrev: string;
  sectionCount: number;
  classSize: number;
  gradeLevel: number; // bit-wise combination of enum GradeLevels
  subject: string;
  teacherList: string[];
  category: string;
  description: string;
  imageUrl: string;
  enabled: boolean;
}

export interface EnrollmentType {
  _id: string;
  cycleId: string;
  courseId: string;
  sectionId: string;
  studentId: string;
}

export interface InterventionType {
  _id: string; // Intervention _id
  name: string; // name
}

export interface RequestType {
  _id: string; // Request _id
  studentId: string; // student ID
  courseKey: string; // course Key
  done: boolean;
}

export interface ScheduleType {
  _id: string;
  stuId: string;
  teacher: string;
  period: string;
}

export interface ScheduleImportType {
  stuId: string;
  p2: string;
  p3: string;
  p4: string;
  p5: string;
  p6: string;
  p7: string;
}

// export interface SettingsType {
//   cycle: number;
//   enableStudentChoice: boolean;
//   minimumStudentRequests: number;
// }
//
export interface StudentType {
  _id: string;
  stuId: string; // Student ID
  lastName: string; // last name
  firstName: string; // first name
  middleName: string; // middle name
  gradeLevel: number; // grade level enum
  ethnicity: string; // ethnicity PS code
  gender: string; // gender M | F
  el: string; // EL proficiency PS code
  sped: boolean; // SPED identified from PS
  mark: boolean;
  requests: string;
  nonRequests: string;
  math: boolean;
  eng: boolean;
  sci: boolean;
  sh: boolean;
  lu: boolean;
  interventionBlame: {
    math: string;
    eng: string;
    sci: string;
    sh: string;
    lu: string;
  };
}

export interface UserClaimsType {
  domain: string;
  email: string;
  firstName: string;
  isStudent: boolean;
  isTeacher: boolean;
  isAdmin: boolean;
  lastName: string;
  uid: string;
  name: string;
}

export interface UserInfoType {
  aud: string;
  claims: UserClaimsType;
  exp: number;
  iat: number;
  iss: string;
  sub: string;
  uid: string;
}

export interface UserType {
  _id: string;
  isAdmin: boolean;
  email: string;
  interventionFlaggingComplete: boolean;
  lastName: string;
  firstName: string;
}

export interface SettingsType {
  cycle: number;
  enableStudentChoices: boolean;
  minStudentRequests: number;
}

export type ImportRecordType = { [prop: string]: string };
