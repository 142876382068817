import React from "react";

const ScheduleImportFields = () => {
  return (
    <div>
      <div className="directions directions-bullets">
        <h5>Schedule information export instructions</h5>
        <ul>
          <li>Login to PowerSchool admin</li>
          <li>Click All to select all students</li>
          <li>Click Special Functions</li>
          <li>Importing &amp; Exporting</li>
          <li>Quick Export</li>
          <li>Use the following field list for a PowerSchool Quick Export:</li>
          <ul>
            <li>Student_Number</li>
            <li>^(*period_info;2(A);teacher_name)</li>
            <li>^(*period_info;3(A);teacher_name)</li>
            <li>^(*period_info;4(A);teacher_name)</li>
            <li>^(*period_info;5(A);teacher_name)</li>
            <li>^(*period_info;6(A);teacher_name)</li>
            <li>^(*period_info;7(A);teacher_name)</li>
          </ul>
          <li>Field delimiter should be "Comma"</li>
          <li>Record delimiter should be "CRLF"</li>
          <li>Check Surround fields with "</li>
          <li>Check Column titles on 1st row</li>
          <li>Click Submit</li>
          <li>Click Choose file</li>
          <li>Select the file that just downloaded</li>
          <li>Click Upload</li>
        </ul>{" "}
      </div>
    </div>
  );
};

export default ScheduleImportFields;
