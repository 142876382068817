import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { selectPropertyValue, selectUserInfo } from "./store/selectors";
import { setActivePageAction } from "./store/actions";
import Admin from "./pages/Admin";
import Login from "./pages/Login";
import { useAppDispatch, useAppSelector } from "./utilities/hooks";
import Navbar from "./components/Navbar";
import "./App.css";
import useInterval from "./hooks/useInterval";
import { refreshTokenActionThunk } from "./store/thunks";
import Intervention from "./pages/Intervention";
import Requests from "./pages/Requests";
import Splash from "./pages/Splash";

function App() {
  const REFRESH_TOKEN_INTERVAL = 55; // minutes
  const choices = [
    { name: "Courses", value: "courses" },
    { name: "Teachers", value: "teachers" },
    { name: "Students", value: "students" },
    { name: "Intervention", value: "intervention" },
    { name: "Scheduler", value: "scheduler" },
    { name: "Lists", value: "lists" },
    { name: "Users", value: "users" },
    { name: "Settings", value: "settings" },
    { name: "Update", value: "update" },
  ];
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(selectPropertyValue("isLoggedIn"));
  const userInfo = useAppSelector(selectUserInfo);
  const activePage = useAppSelector(selectPropertyValue("activePage"));
  const error = useAppSelector(selectPropertyValue("error"));
  const dataStatus = useAppSelector(selectPropertyValue("dataStatus"));

  const refreshToken = () => {
    dispatch(refreshTokenActionThunk(null));
  };

  useInterval(refreshToken, REFRESH_TOKEN_INTERVAL * 60 * 1000);

  const onChangeActivePage = (newPage: string) => {
    dispatch(setActivePageAction(newPage));
  };

  const getBody = () => {
    if (isLoggedIn && userInfo) {
      if (userInfo.isStudent) {
        return <Requests />;
      } else if (userInfo.isAdmin) {
        if (Object.values(dataStatus).every((i) => i)) {
          return (
            <div>
              <Navbar
                choices={choices}
                selectedValue={activePage}
                onChange={onChangeActivePage}
                className="app-navbar"
              />
              <Admin />
            </div>
          );
        } else {
          return <Splash dataStatus={dataStatus} />;
        }
      } else {
        return (
          <div>
            <Intervention forTeacher={true} />
          </div>
        );
      }
    } else {
      return <Login />;
    }
  };

  const getError = () => {
    return (
      <div>
        <h4>There's a problem with the program</h4>
        <h6
          className="text-start"
          dangerouslySetInnerHTML={{
            __html: error.stack.replace("\n", "<br/>"),
          }}
        ></h6>
      </div>
    );
  };

  return (
    <div className="container">
      <div className="card">
        <div className="card-header">
          <Header />
        </div>
        <div className="card-body container-body">
          {error ? getError() : getBody()}
        </div>
        <div className="card-footer">
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default App;
