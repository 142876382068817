import React, { useState } from "react";
import StudentImportFields from "./StudentImportFields";
import ScheduleImportFields from "./ScheduleImportFields";
import ParseData from "./ParseData";
import isCsvValid from "./isCsvValid";
import { useAppDispatch, useAppSelector } from "../../utilities/hooks";
import {
  GradeLevels,
  ImportRecordType,
  StudentType,
} from "../../models/ModelTypes";
import "./Update.css";
import { selectPropertyValue } from "../../store/selectors";
import { updateThunk } from "../../store/thunks";
import { hasUpdatedAction, isUpdatingAction } from "../../store/actions";

const clone = require("ramda/src/clone");

const Update = () => {
  const [showStudent, setShowStudent] = useState(true);
  const [skipFirst, setSkipFirst] = useState(true);
  const [message, setMessage] = useState("");
  const token = useAppSelector(selectPropertyValue("id_token"));
  let oldStudentList: StudentType[] = clone(
    useAppSelector<StudentType[]>(selectPropertyValue("students"))
  );
  const dispatch = useAppDispatch();

  const studentFields = [
    "stuId",
    "lastName",
    "firstName",
    "middleName",
    "gradeText",
    "ethnicity",
    "gender",
    "el",
    "sped",
  ];

  const studentFieldsRegex = [
    /[0-9]+/,
    /[a-zA-Z-]+/,
    /[a-zA-Z-]+/,
    /[a-zA-Z-]*/,
    /[6-8]/,
    /[0-9]*/,
    /M|F|X|\s/,
    /[a-zA-Z0-9]*/,
    /[a-zA-Z0-9]*/,
  ];

  const scheduleFields = ["stuId", "p2", "p3", "p4", "p5", "p6", "p7"];

  const scheduleFieldsRegex = [
    /[0-9]+/,
    /[a-zA-Z0-9,\-\s]*/,
    /[a-zA-Z0-9,\-\s]*/,
    /[a-zA-Z0-9,\-\s]*/,
    /[a-zA-Z0-9,\-\s]*/,
    /[a-zA-Z0-9,\-\s]*/,
    /[a-zA-Z0-9,\-\s]*/,
  ];

  const switchData = (type: string) => () => {
    setShowStudent(type === "student");
    // }
    // setShowStudent(!showStudent);
    // dispatch(clearHasUpdated());
  };

  const toggleSkipFirst = () => {
    setSkipFirst(!skipFirst);
    // dispatch(clearHasUpdated());
  };

  const uploadStudent = (csv: string[][]) => {
    // Validate CSV file
    const { isValid, errorInLine, errorInField, errorInLength } = isCsvValid(
      csv,
      studentFieldsRegex
    );

    if (isValid) {
      // Read new student data
      const newStudentList: StudentType[] = csv.reduce(
        (acc: StudentType[], line: string[]): StudentType[] => {
          let obj: any = {};
          for (let i = 0; i < studentFields.length; i++) {
            obj[studentFields[i]] = line[i];
          }
          obj.gradeLevel =
            (obj.gradeText === "6" ? GradeLevels.SIX : GradeLevels.NONE) +
            (obj.gradeText === "7" ? GradeLevels.SEVEN : GradeLevels.NONE) +
            (obj.gradeText === "8" ? GradeLevels.EIGHT : GradeLevels.NONE);
          if (obj.lastName) {
            acc.push(obj as StudentType);
          }
          return acc;
        },
        []
      );

      // Unmark students in the old list so we only save the ones we've updated
      // from the PS data
      oldStudentList = oldStudentList.map((s) => {
        s.mark = false;
        return s;
      });

      // Merge new with old student data
      const toBeUploaded: StudentType[] = [];
      newStudentList.forEach((ns) => {
        let existingStudent = oldStudentList.find(
          (exS) => exS.stuId === ns.stuId
        );
        if (existingStudent) {
          existingStudent = { ...existingStudent, ...ns };
          // existingStudent.mark = true;
          toBeUploaded.push(existingStudent);
        } else {
          const toAdd = {
            ...ns,
            // mark: true,
            requests: "",
            nonRequests: "",
            math: false,
            eng: false,
            sci: false,
            sh: false,
            lu: false,
          };
          toBeUploaded.push(toAdd);
          // oldStudentList.push(toAdd);
        }
      });
      console.log(toBeUploaded);
      // const listToSave = oldStudentList.filter((s) => s.mark);
      dispatch(isUpdatingAction(true));
      dispatch(hasUpdatedAction(false));
      dispatch(
        updateThunk({
          collectionName: "students",
          records: toBeUploaded,
          deleteFirst: false,
          token,
        })
      );
    } else {
      if (errorInLength) {
        setMessage("Imported data has the wrong number of fields");
      } else {
        setMessage(
          `Error in line ${errorInLine + 1}, field ${errorInField + 1}`
        );
      }
    }
  };

  const uploadSchedule = (csv: string[][]) => {
    const { isValid, errorInLine, errorInField, errorInLength } = isCsvValid(
      csv,
      scheduleFieldsRegex
    );
    if (isValid) {
      const newSchedulesList: any = csv.map((line) => {
        let obj: ImportRecordType = {};
        for (let i = 0; i < scheduleFields.length; i++) {
          obj[scheduleFields[i]] = line[i];
        }
        return obj;
      });
      const fields = ["p2", "p3", "p4", "p5", "p6", "p7"];
      const records: any[] = newSchedulesList.reduce(
        (acc: any[], s: ImportRecordType) => {
          const stuId = s.stuId;
          fields.forEach((field) => {
            const nRec = {
              stuId,
              teacher: s[field],
              period: field.substring(1),
            };
            acc.push(nRec);
          });
          return acc;
        },
        []
      );
      dispatch(isUpdatingAction(true));
      dispatch(hasUpdatedAction(false));
      dispatch(
        updateThunk({
          records,
          token,
          collectionName: "schedules",
          deleteFirst: true,
        })
      );
    } else {
      if (errorInLength) {
        setMessage("Imported data has the wrong number of fields");
      } else {
        setMessage(
          `Error in line ${errorInLine + 1}, field ${errorInField + 1}`
        );
      }
    }
  };

  return (
    <div className="row">
      <div className="col-md-6 text-left">
        <div>
          <button
            className={showStudent ? "btn btn-primary" : "btn btn-secondary"}
            onClick={switchData("student")}
          >
            Import student
          </button>
          <button
            className={showStudent ? "btn btn-secondary" : "btn btn-primary"}
            onClick={switchData("schedule")}
          >
            Import schedule
          </button>
        </div>
        <div style={{ marginTop: "15px" }}>
          <input
            type="checkbox"
            onChange={toggleSkipFirst}
            checked={skipFirst}
          />
          {"  Skip first line"}
        </div>
        <div>{message}</div>
      </div>
      <div className="col-md-6 text-left">
        <h4>{showStudent ? "Student" : "Schedule"} Information Fields</h4>
        <ParseData
          upload={showStudent ? uploadStudent : uploadSchedule}
          skipFirst={skipFirst}
        />
        {/*{hasUpdated ? (*/}
        {/*  <h5 style={{ color: "green" }}>{hasUpdatedDataType} was updated</h5>*/}
        {/*) : (*/}
        {/*  ""*/}
        {/*)}*/}
        {showStudent ? <StudentImportFields /> : <ScheduleImportFields />}
      </div>
    </div>
  );
};

export default Update;
