import React from "react";

const StudentImportFields = () => {
  return (
    <div className="directions directions-bullets">
      <h5>Student information export instructions</h5>
      <ul>
        <li>Login to PowerSchool admin</li>
        <li>Click All to select all students</li>
        <li>Click Special Functions</li>
        <li>Importing &amp; Exporting</li>
        <li>Quick Export</li>
        <li>Use the following field list for a PowerSchool Quick Export:</li>
        <ul>
          <li>Student_Number</li>
          <li>Last_Name</li>
          <li>First_Name</li>
          <li>Middle_Name</li>
          <li>Grade_Level</li>
          <li>Ethnicity</li>
          <li>Gender</li>
          <li>U_Students.EL_PROFLEVEL</li>
          <li>CA_SpEd504</li>
        </ul>
        <li>Field delimiter should be "Comma"</li>
        <li>Record delimiter should be "CRLF"</li>
        <li>Check Surround fields with "</li>
        <li>Check Column titles on 1st row</li>
        <li>Click Submit</li>
        <li>Click Choose file</li>
        <li>Select the file that just downloaded</li>
        <li>Click Upload</li>
      </ul>
    </div>
  );
};

export default StudentImportFields;
