import React, { useEffect, useState } from "react";
import { selectPropertyValue, selectUserInfo } from "../store/selectors";
import {
  CoursePriorities,
  CourseType,
  EnrollmentType,
  StudentType,
  UserType,
} from "../models/ModelTypes";
import { useAppDispatch, useAppSelector } from "../utilities/hooks";
import "./Requests.css";
import { API_URL, IMAGE_URL, CONTACT_NAME, CONTACT_EMAIL } from "../constants";
import { updateThunk } from "../store/thunks";
import { setDirtyAction } from "../store/actions";

const clone = require("ramda/src/clone");

const Requests = () => {
  const [showCurrentCourse, setShowCurrentCourse] = useState(false);
  const [student, setStudent] = useState<StudentType | null>(null);
  const [requests, setRequests] = useState<string[]>([]);
  // const [dirty, setDirty] = useState(false);

  const settings = useAppSelector(selectPropertyValue("settings"));
  const enrollments = useAppSelector(selectPropertyValue("enrollments"));
  const courses = useAppSelector(selectPropertyValue("courses"));
  const userInfo = useAppSelector<UserType>(selectUserInfo);
  const token = useAppSelector<string>(selectPropertyValue("id_token"));
  const dirty = useAppSelector<boolean>(selectPropertyValue("dirty"));

  const dispatch = useAppDispatch();

  useEffect(() => {
    fetch(API_URL + "/get", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        collectionName: "students",
        filterField: "stuId",
        filterValue: userInfo.email.split("@")[0],
        token,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          console.log(res);
        } else {
          setStudent(res.items[0] ?? null);
        }
      });
  }, [userInfo?.email, token]);

  useEffect(() => {
    setRequests(
      student
        ? student.requests.split(",").filter((s: string) => s.length > 0)
        : []
    );
  }, [student]);

  const name =
    userInfo.firstName +
    " " +
    userInfo.lastName +
    " (ID: " +
    userInfo.email.split("@")[0] +
    ")";

  const toggleRequest = (evt: React.MouseEvent<HTMLDivElement>) => {
    const cId = evt.currentTarget.getAttribute("data-id");
    if (!cId) return;
    const idx = requests.indexOf(cId);
    const req = clone(requests);
    if (idx < 0) {
      req.push(cId);
    } else {
      req.splice(idx, 1);
    }
    setRequests(req);
    dispatch(setDirtyAction(true));
  };

  const toggleCurrentCourse = () => setShowCurrentCourse((s) => !s);

  const sortedCourses = clone(courses).sort((a: CourseType, b: CourseType) =>
    a.name < b.name ? -1 : 1
  );

  const enrolledCourse = () => {
    if (!enrollments || !courses) return null;
    const enrollment = enrollments.find(
      (e: EnrollmentType) =>
        e.cycleId === settings.cycle && e.studentId === student?._id
    );
    if (!enrollment) return null;
    const course = courses.find(
      (c: CourseType) => c._id === enrollment.courseId
    );
    if (!course) return null;
    return (
      <>
        <h5>For the current cycle you are enrolled in</h5>
        <h6>
          <span className="bold-header">Course</span>: {course.name}
        </h6>
        <h6>
          <span className="bold-header">Teacher</span>:{" "}
          {course.teacherList[parseInt(enrollment.sectionId, 10) - 1]}
        </h6>
      </>
    );
    // return enrollment;
  };

  const cancel = () => {
    setRequests(
      student
        ? student.requests.split(",").filter((r: string) => r.length > 0)
        : []
    );
    dispatch(setDirtyAction(false));
  };

  const save = () => {
    if (!student) return;
    dispatch(
      updateThunk({
        collectionName: "students",
        records: [{ _id: student._id, requests: requests.join(",") }],
        token,
        deleteFirst: false,
      })
    );
  };

  let row = 0,
    col = 0;
  const grid = sortedCourses.reduce(
    (grid: CourseType[][], c: CourseType) => {
      if (c && (!c.enabled || c.priority !== CoursePriorities.EXPLORATION))
        return grid;
      if (col === 4) {
        row++;
        col = 0;
        grid[row] = [];
      }
      grid[row].push(c);
      col++;
      return grid;
    },
    [[]]
  );
  // console.log(grid);

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-header">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <div>{name}</div>
                {!showCurrentCourse ? (
                  <h6>Click a course card to request or un-request</h6>
                ) : null}
                {!showCurrentCourse ? (
                  <h6>
                    {requests.length < settings?.minimumStudentRequests
                      ? "You need to select at least " +
                        settings?.minimumStudentRequests +
                        " courses. Select " +
                        (settings?.minimumStudentRequests - requests.length) +
                        " more."
                      : "You have selected " + requests.length + " courses"}
                  </h6>
                ) : (
                  enrolledCourse()
                )}
                {!showCurrentCourse ? (
                  <>
                    <h6 className="disclaimer disclaimer-border">
                      A minimum of {settings?.minimumStudentRequests} selections
                      must be made. By selecting these courses, I agree to be
                      placed into these classes for a three week cycle.
                    </h6>
                    <h6 className="disclaimer">
                      Selecting these courses does not guarantee enrollment in
                      these courses. Choose your selections carefully, once you
                      are placed into an Exploration course, you will not be
                      allowed to transfer out for reasons other than medical or
                      academic necessity, to be determined by the
                      administration.
                    </h6>
                    <h6 className="disclaimer">
                      These courses are an “Exploration”, designed to expose
                      students to new skills, talents, and interests. They are
                      only three weeks long, so give it a shot; you may find
                      your newest hobby or talent.
                    </h6>

                    <h6 className="disclaimer">
                      Permission to take the same course again must be given by
                      the program’s administrator and teacher. Please email {CONTACT_NAME} with questions or requests at {CONTACT_EMAIL}
                    </h6>

                    <h6 className="disclaimer">
                      By clicking the Save button, I acknowledge and agree to
                      these terms and conditions.
                    </h6>
                  </>
                ) : null}
              </div>
              <div className="col-md-2">
                <div className="row">
                  <div className="col-md-12">
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={toggleCurrentCourse}
                    >
                      {showCurrentCourse
                        ? "Show requests"
                        : "Show current course"}
                    </button>
                  </div>
                </div>
                {!showCurrentCourse ? (
                  <div className="row">
                    <div className="col-md-12">
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={save}
                        disabled={
                          !dirty ||
                          requests.length < settings?.minimumStudentRequests
                        }
                      >
                        Save
                      </button>
                      <button
                        className="btn btn-sm btn-primary"
                        disabled={!dirty}
                        onClick={cancel}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {!showCurrentCourse ? (
            <div className="card-body">
              {grid.map((r: CourseType[], idx: number) => {
                return (
                  <div className="row" key={"request" + idx}>
                    {r.map((c: CourseType) => {
                      return (
                        <div className="col-md-3" key={c._id}>
                          <div
                            className={
                              requests.includes(c._id)
                                ? "card mb-3 course-card requested"
                                : "card mb-3 course-card"
                            }
                            data-id={c._id}
                            onClick={toggleRequest}
                          >
                            <div className="card-header">{c.name}</div>
                            <div className="card-body">
                              <div className="card-text">{c.description}</div>
                              <div className="card-text">
                                <small className="text-muted">
                                  {c.category}
                                </small>
                              </div>
                            </div>
                            {c.imageUrl ? (
                              <div className="card-footer">
                                <img
                                  src={IMAGE_URL + c.imageUrl}
                                  className="img-fluid rounded-start"
                                  alt="Course icon"
                                />
                              </div>
                            ) : null}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
              <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-4"></div>
                <div className="col-md-4"></div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Requests;
