import { AnyAction, AsyncThunk, createReducer } from "@reduxjs/toolkit";
import { initialState } from "./initState";
import {
  getCollectionAction,
  loginAction,
  refreshTokenAction,
  setActivePageAction,
  setDirtyAction,
  setGradeLevelDataAction,
  setSchedulerStateAction,
  thrownErrorAction,
  triggerUpdateFlag,
  setListStateAction,
} from "./actions";

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
type PendingAction = ReturnType<GenericAsyncThunk["pending"]>;
type FulfilledAction = ReturnType<GenericAsyncThunk["fulfilled"]>;
function isPendingAction(action: AnyAction): action is PendingAction {
  return action.type.endsWith("/pending");
}

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(loginAction, (state, action) => {
    state.isLoggedIn = true;
    state.id_token = action.payload;
  });
  builder.addCase(triggerUpdateFlag, (state, action) => {
    state.updateFlag = state.updateFlag + 1;
  });
  builder.addCase(refreshTokenAction, (state, action) => {
    state.id_token = action.payload;
  });
  builder.addCase(setActivePageAction, (state, action) => {
    state.activePage = action.payload;
  });
  builder.addCase(setListStateAction, (state, action) => {
    state.listState = action.payload;
  });
  builder.addCase(thrownErrorAction, (state, action) => {
    state.error = action.payload;
  });
  builder.addCase(setDirtyAction, (state, action) => {
    state.dirty = action.payload;
  });
  builder.addCase(setSchedulerStateAction, (state, action) => {
    state.schedulerState = action.payload;
  });
  builder.addCase(setGradeLevelDataAction, (state, action) => {
    state.gradeLevelData = action.payload;
  });
  builder.addCase(getCollectionAction, (state, action) => {
    switch (action.payload.collectionName) {
      case "users":
        state.users = action.payload.items;
        state.dataStatus.users = true;
        break;
      case "schedules":
        state.schedules = action.payload.items;
        state.dataStatus.schedules = true;
        break;
      case "students":
        state.students = action.payload.items;
        state.dataStatus.students = true;
        break;
      case "courses":
        state.courses = action.payload.items;
        state.dataStatus.courses = true;
        break;
      case "requests":
        state.requests = action.payload.items;
        // state.dataStatus.requests = true;
        break;
      case "settings":
        state.settings = action.payload.items[0];
        state.dataStatus.settings = true;
        break;
      case "enrollments":
        state.enrollments = action.payload.items;
        state.dataStatus.enrollments = true;
        break;
    }
  });
  builder.addMatcher(isPendingAction, (state, action) => {
    state.inProcess++;
  });
  builder.addMatcher<FulfilledAction>(
    (action) => action.type.endsWith("/fulfilled"),
    (state, action) => {
      state.inProcess = Math.max(state.inProcess - 1, 0);
    }
  );
  // builder.addCase(getSettings, (state, action: any) => {
  //   state = { state, ...action.payload };
  // });
});
