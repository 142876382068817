import React from "react";
import { selectPropertyValue } from "../store/selectors";
import { useAppSelector } from "../utilities/hooks";
import SelectTab from "./SelectTab";
import Update from "./update/Update";
import Users from "./Users";
import { Settings } from "./Settings";
import Courses from "./Courses";
import Teachers from "./Teachers";
import Intervention from "./Intervention";
import Scheduler from "./scheduler/Scheduler";
import Students from "./student/Students";
import List from "./lists/Lists";

const Admin = () => {
  const activePage = useAppSelector(selectPropertyValue("activePage"));
  const getComponent = (activePage: string) => {
    switch (activePage) {
      case "":
        return <SelectTab />;

      case "students":
        return <Students />;

      case "update":
        return <Update />;

      case "users":
        return <Users />;

      case "settings":
        return <Settings />;

      case "courses":
        return <Courses />;

      case "teachers":
        return <Teachers />;

      case "intervention":
        return <Intervention />;

      case "scheduler":
        return <Scheduler />;

      case "lists":
        return <List />;
    }
  };
  return <div>{getComponent(activePage)}</div>;
};

export default Admin;
