import React from "react";
import Navbar from "../../components/Navbar";
import Year from "./Year";
import GradeLevel from "./GradeLevel";
import { useAppDispatch, useAppSelector } from "../../utilities/hooks";
import { selectPropertyValue } from "../../store/selectors";
import { setSchedulerStateAction } from "../../store/actions";
import Intervention from "./Intervention";
import Autoschedule from "./Autoschedule";
import "./Scheduler.css";
import ManualEntry from "./ManualEntry";
import Counts from "./Counts";

const Scheduler = () => {
  const schedulerState = useAppSelector(selectPropertyValue("schedulerState"));
  const dispatch = useAppDispatch();

  const navbarButtons = [
    { name: "Intervention counts", value: "counts" },
    { name: "Step 1: Intervention", value: "intervention" },
    { name: "Step 2: Grade level", value: "gradeLevel" },
    { name: "Step 3: Year courses", value: "year" },
    { name: "Step 4: Auto-schedule", value: "autoschedule" },
    { name: "Step 5: Manual entry", value: "manual" },
  ];

  const changeSchedulerState = (choice: string) => {
    dispatch(setSchedulerStateAction(choice));
  };

  const getComponent = (schedulerState: string) => {
    switch (schedulerState) {
      case "counts":
        return <Counts />;

      case "gradeLevel":
        return <GradeLevel />;

      case "year":
        return <Year />;

      case "intervention":
        return <Intervention />;

      case "autoschedule":
        return <Autoschedule />;

      case "manual":
        return <ManualEntry />;

      default:
        return <div>Choose a step...</div>;
    }
  };

  return (
    <div>
      <Navbar
        choices={navbarButtons}
        selectedValue={schedulerState}
        onChange={changeSchedulerState}
        className="scheduler-navbar"
      />
      {getComponent(schedulerState)}
    </div>
  );
};

export default Scheduler;
