import React from "react";
import { useAppDispatch } from "../utilities/hooks";
import { thrownErrorAction } from "../store/actions";

const Header = () => {
  const dispatch = useAppDispatch();
  const throwError = () => {
    dispatch(thrownErrorAction(new Error("TestError")));
  };

  return (
    <div onClick={throwError}>
      <div>Marston Exploration Course Scheduler</div>
    </div>
  );
};

export default Header;
