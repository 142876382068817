import React, { useEffect, useState } from "react";
import { selectPropertyValue } from "../../store/selectors";
import {
  CoursePriorities,
  CourseType,
  EnrollmentType,
} from "../../models/ModelTypes";
import PriorityCourseList from "./PriorityCourseList";
import { useAppDispatch, useAppSelector } from "../../utilities/hooks";
import { removeManyThunk, updateThunk } from "../../store/thunks";
import { toGradeLevel } from "../../utilities/toGradeLevel";
import { API_URL } from "../../constants";
import { randomlySelect as _randomlySelect } from "../../utilities/randomlySelect";
import "./Scheduler.css";

const { without, clone, count } = require("ramda");

const Year = () => {
  const [selectedCourseId, setSelectedCourseId] = useState<string>("");
  const [section, setSection] = useState<string>("1");
  const [showAllStudents, setShowAllStudents] = useState<boolean>(false);
  const [inProcess, setInProcess] = useState<boolean>(false);
  const [studentData, setStudentData] = useState<any[]>([]);

  const [selectedUnenrolledStudents, setSelectedUnenrolledStudents] = useState<
    string[]
  >([]);
  const [selectedEnrolledStudents, setSelectedEnrolledStudents] = useState<
    string[]
  >([]);

  const settings = useAppSelector(selectPropertyValue("settings"));
  const courses = useAppSelector(selectPropertyValue("courses"));
  const token = useAppSelector<string>(selectPropertyValue("id_token"));
  const updateFlag = useAppSelector<number>(selectPropertyValue("updateFlag"));
  const thisCourse = courses.find(
    (c: CourseType) => c._id === selectedCourseId
  ) as CourseType;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (settings?.cycle && selectedCourseId)
      fetch(API_URL + "/getStudentsForYearElectiveCourse", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          courseId: selectedCourseId,
          cycle: settings.cycle,
          token,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.error) {
            console.log(res);
          } else {
            setStudentData(res.studentData);
            setInProcess(false);
          }
        });
  }, [selectedCourseId, settings?.cycle, updateFlag, token]);

  const setNewCourse = (_id: string) => {
    setStudentData([]);
    setSelectedCourseId(_id);
  };

  const sectionButtonClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
    setSection(evt.currentTarget.getAttribute("data-value") ?? "1");
  };

  const clickStudent =
    (type: "enrolled" | "unenrolled") =>
    (evt: React.MouseEvent<HTMLTableRowElement>) => {
      const list =
        type === "enrolled"
          ? clone(selectedEnrolledStudents)
          : clone(selectedUnenrolledStudents);
      const setter: React.Dispatch<React.SetStateAction<string[]>> =
        type === "enrolled"
          ? setSelectedEnrolledStudents
          : setSelectedUnenrolledStudents;
      const thisId = evt.currentTarget.getAttribute("data-id") as string;
      if (list.includes(thisId)) {
        setter(without(thisId, list));
      } else {
        list.push(thisId);
        setter(list);
      }
    };

  const toggleShowAllStudents = () => {
    setShowAllStudents(!showAllStudents);
  };

  const enroll = () => {
    setInProcess(true);
    const newEnrollments = selectedUnenrolledStudents.map((sId: any) => {
      const thisStudent = studentData.find((s: any) => s._id === sId);

      let rec: EnrollmentType = thisStudent.enrollmentRecord
        ? {
            ...thisStudent.enrollmentRecord,
            courseId: selectedCourseId,
            sectionId: section,
          }
        : {
            _id: "",
            cycleId: settings.cycle,
            courseId: selectedCourseId,
            sectionId: section,
            studentId: sId,
          };
      return rec;
    });
    setSelectedUnenrolledStudents([]);
    dispatch(
      updateThunk({
        collectionName: "enrollments",
        records: newEnrollments,
        deleteFirst: false,
        token,
      })
    );
  };

  const unenroll = () => {
    setInProcess(true);
    dispatch(
      removeManyThunk({
        collectionName: "enrollments",
        ids: selectedEnrolledStudents,
        token,
      })
    );
    setSelectedEnrolledStudents([]);
  };

  const randomlySelect = () => {
    const fromThese = studentData.filter(
      (s: any) => s.matchCourseCriteria && !s.enrollmentRecord
    );
    const enrolledCount = count(
      (s: any) =>
        s.enrollmentRecord &&
        s.enrollmentRecord.courseId === selectedCourseId &&
        s.enrollmentRecord.sectionId === section,
      studentData
    );
    let space =
      thisCourse.classSize - enrolledCount - selectedUnenrolledStudents.length;
    space = Math.min(space, fromThese.length);
    const sus = _randomlySelect(fromThese, selectedUnenrolledStudents, space);
    setSelectedUnenrolledStudents(sus);
  };

  const createSectionButtons = () => {
    if (!thisCourse || !studentData) return;
    const arr = [];
    for (let i = 1; i <= thisCourse.sectionCount; i++) {
      const sectionCount = count(
        (student: any) =>
          student.enrollmentRecord &&
          student.enrollmentRecord.courseId === selectedCourseId &&
          student.enrollmentRecord.sectionId + "" === i + "",
        studentData
      );
      let className = "btn btn-enroll btn-block mb-3 ";
      className += i + "" === section + "" ? "btn-success" : "btn-secondary";
      arr.push(
        <button
          onClick={sectionButtonClick}
          key={"section" + i}
          className={className}
          disabled={false}
          data-value={i}
        >
          {`Sec ${i} (${sectionCount})`}
          <div className="intervention-teacher-name">
            {thisCourse.teacherList[i - 1]}
          </div>
        </button>
      );
    }
    return arr;
  };

  const enrolled =
    studentData.reduce((array: any[], s) => {
      if (s.enrollmentRecord?.courseId === selectedCourseId) {
        let className = ["student-list-item"];
        if (selectedEnrolledStudents.includes(s.enrollmentRecord._id)) {
          className.push("active-student");
        }
        if (s.enrollmentRecord.sectionId + "" === section + "") {
          className.push("in-this-section");
        }
        array.push(
          <tr
            data-id={s.enrollmentRecord._id}
            key={s.enrollmentRecord._id}
            onClick={clickStudent("enrolled")}
            className={className.join(" ")}
          >
            <td className="text-start">
              {s.enrollmentRecord.sectionId} {s.lastName}, {s.firstName}{" "}
              {`(Gr ${toGradeLevel(s.gradeLevel)} | ID: ${s.stuId})`}
            </td>
          </tr>
        );
      }
      return array;
    }, []) ?? [];

  return (
    <div className={inProcess ? "row update-in-process" : "row"}>
      <div className="col-md-6">
        <div className="mb-3 row">
          <label className="col-sm-3 col-form-label">Course:</label>
          <div className="col-sm-9">
            <PriorityCourseList
              title="Year-long Req'd and Elective Course List"
              selectedCourseId={selectedCourseId}
              onCourseSelected={setNewCourse}
              priority={CoursePriorities.YEAR_COURSE}
            />
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <input
          type="checkbox"
          checked={showAllStudents}
          onChange={toggleShowAllStudents}
        />
        <span className="left-spacer">Show all students</span>
      </div>
      <div id="dim-this" className="col-md-5 scheduler-year-container">
        <table className="table table-sm table-bordered table-condensed">
          <thead>
            <tr>
              <th className="table-header">
                {`Unenrolled (${selectedUnenrolledStudents.length} selected)`}
                <button
                  className="btn btn-small btn-primary btn-randomly-select"
                  onClick={randomlySelect}
                >
                  Random
                </button>
                <button
                  className="btn btn-small btn-primary btn-randomly-select"
                  onClick={() => setSelectedUnenrolledStudents([])}
                >
                  Clear
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {studentData
              ? studentData.reduce((array: any[], s) => {
                  if (
                    s.enrollmentRecord?.courseId !== selectedCourseId &&
                    (showAllStudents || s.matchCourseCriteria)
                  ) {
                    let className = "student-list-item ";
                    if (selectedUnenrolledStudents.includes(s._id)) {
                      className += "active-student ";
                    }
                    if (!s.matchCourseCriteria) {
                      className += "no-course-match";
                    }
                    array.push(
                      <tr
                        data-id={s._id}
                        key={s._id}
                        onClick={clickStudent("unenrolled")}
                        className={className}
                      >
                        <td className="text-start">
                          {s.thisCourseWasAlreadyTaken ? (
                            <span className="already-taken">&#10003; </span>
                          ) : (
                            ""
                          )}
                          {s.lastName}, {s.firstName}{" "}
                          {`(Gr ${toGradeLevel(s.gradeLevel)} | ID: ${
                            s.stuId
                          })`}
                          <span className="enrolled-course">
                            {s.enrolledCourse
                              ? " " + s.enrolledCourse.name
                              : ""}
                          </span>
                        </td>
                      </tr>
                    );
                  }
                  return array;
                }, [])
              : []}
          </tbody>
        </table>
      </div>
      <div className="col-md-2">
        <div>
          {createSectionButtons()}
          <button
            onClick={enroll}
            className="btn btn-primary btn-enroll btn-block mb-3"
            disabled={selectedUnenrolledStudents.length === 0}
          >
            {"Enroll =>"}
          </button>
        </div>
        <div>
          <button
            onClick={unenroll}
            className="btn btn-primary btn-unenroll btn-block"
            disabled={selectedEnrolledStudents.length === 0}
          >
            {"<= Unenroll"}
          </button>
        </div>
      </div>
      <div id="dim-this-too" className="col-md-5 scheduler-year-container">
        <table className="table table-sm table-bordered table-condensed">
          <thead>
            <tr>
              <th>
                Enrolled ({enrolled.length} /{" "}
                {thisCourse
                  ? thisCourse.sectionCount * thisCourse.classSize
                  : 0}
                )
              </th>
            </tr>
          </thead>
          <tbody>{enrolled}</tbody>
        </table>
      </div>
    </div>
  );
};

export default Year;
