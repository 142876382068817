import { createAction } from "@reduxjs/toolkit";

export const setActivePageAction = createAction<string>("setActivePage");
export const getSettings = createAction("getSettings");
export const loginAction = createAction<string>("loginAction");
export const refreshTokenAction = createAction<string>("refreshToken");
export const getCollectionAction = createAction<{
  collectionName: string;
  items: any[];
}>("getCollectionAction");
export const isUpdatingAction = createAction<boolean>("isUpdating");
export const hasUpdatedAction = createAction<boolean>("hasUpdated");
export const setDirtyAction = createAction<boolean>("setDirty");
export const setSchedulerStateAction = createAction<string>(
  "setSchedulerStateAction"
);
export const setListStateAction = createAction<string>("setListStateAction");
export const setGradeLevelDataAction = createAction<any[]>(
  "setGradeLevelDataAction"
);
export const triggerUpdateFlag = createAction("triggerUpdateFlag");

export const thrownErrorAction = createAction<any>("thrownErrorAction");
