import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../utilities/hooks";
import { selectPropertyValue } from "../../store/selectors";
import {
  CourseType,
  EnrollmentType,
  StudentType,
} from "../../models/ModelTypes";
import { clone, groupBy } from "ramda";
import { download } from "../../utilities/download";

const Passes = () => {
  const [sortByTeacherRoom, setSortByTeacherRoom] = useState(true);
  const [thisCycle, setThisCycle] = useState("0");
  const [lastCycle, setLastCycle] = useState("0");
  const students = clone(useAppSelector(selectPropertyValue("students"))).sort(
    (a: StudentType, b: StudentType) =>
      a.lastName.toLowerCase() + "," + a.firstName.toLowerCase() <
      b.lastName.toLowerCase() + "," + b.firstName.toLowerCase()
        ? -1
        : 1
  );
  const enrollments = useAppSelector(selectPropertyValue("enrollments"));
  const courses = useAppSelector(selectPropertyValue("courses"));
  const settings = useAppSelector(selectPropertyValue("settings"));

  useEffect(() => {
    if (!settings?.cycle) return;
    const c = parseInt(settings.cycle, 10);
    setThisCycle(c + "");
    setLastCycle(c - 1 + "");
  }, [settings?.cycle]);

  const toggleSort = () => {
    setSortByTeacherRoom((st) => !st);
  };

  const getCourseNameAndTeacher = (courseId: string, sectionId: string) => {
    const c = courses.find((c: CourseType) => c._id === courseId) ?? null;
    return c
      ? {
          name: c.name,
          section: sectionId,
          teacher: c.teacherList
            ? c.teacherList[parseInt(sectionId, 10) - 1 + ""]
            : "",
        }
      : { name: "Not enrolled", section: "", teacher: "" };
  };

  let enrollmentsByCycle: { [id: string]: EnrollmentType[] } | null = null;
  let enrollmentsByStudentThisCycle: { [id: string]: EnrollmentType[] } = {},
    enrollmentsByStudentLastCycle: { [id: string]: EnrollmentType[] } = {};
  if (enrollments) {
    enrollmentsByCycle = groupBy((e: EnrollmentType) => e.cycleId, enrollments);
    enrollmentsByStudentThisCycle = groupBy(
      (e: EnrollmentType) => e.studentId,
      enrollmentsByCycle[thisCycle] ? enrollmentsByCycle[thisCycle] : []
    );
    enrollmentsByStudentLastCycle = groupBy(
      (e: EnrollmentType) => e.studentId,
      enrollmentsByCycle[lastCycle] ? enrollmentsByCycle[lastCycle] : []
    );
  }

  const downloadData = () => {
    const csv = getCsv();
    download(
      csv,
      "Student passes for cycle " + settings.cycle + ".csv",
      "application/octet-stream"
    );
  };

  const getData = (): string[][] => {
    let rows: string[][] = [];
    students.forEach((s: StudentType) => {
      const thisCycle =
        enrollmentsByStudentThisCycle && enrollmentsByStudentThisCycle[s._id]
          ? enrollmentsByStudentThisCycle[s._id][0]
          : null;
      const lastCycle =
        enrollmentsByStudentLastCycle && enrollmentsByStudentLastCycle[s._id]
          ? enrollmentsByStudentLastCycle[s._id][0]
          : null;
      console.log(thisCycle, lastCycle);
      const thisCourse = getCourseNameAndTeacher(
        thisCycle?.courseId ?? "",
        thisCycle?.sectionId ?? ""
      );
      const lastCourse = getCourseNameAndTeacher(
        lastCycle?.courseId ?? "",
        lastCycle?.sectionId ?? ""
      );
      rows.push([
        `"${s.stuId}"`,
        `"${s.lastName}, ${s.firstName}"`,
        `"${lastCourse ? lastCourse.name : "No course"}"`,
        `"${lastCourse ? lastCourse.teacher : ""}"`,
        // `"${lastCourse ? lastCourse.section : ""} ${
        //   lastCourse ? lastCourse.teacher : ""
        // }"`,
        `"${thisCourse ? thisCourse.name : "No course"}"`,
        `"${thisCourse ? thisCourse.teacher : ""}"`,
        // `"${thisCourse ? thisCourse.section : ""} ${
        //   thisCourse ? thisCourse.teacher : ""
        // }"`,
      ]);
    });
    rows.sort((a: string[], b: string[]) => {
      if (sortByTeacherRoom) {
        return a[2] < b[2] ? -1 : 1;
      } else {
        return a[1] < b[1] ? -1 : 1;
      }
    });
    return rows;
  };

  const getCsv = () => {
    let arr = [
      [
        "Student ID",
        "Name",
        "Cycle " + thisCycle + " Course",
        "Section/Teacher",
        "Cycle " + lastCycle + " Course",
        "Section/Teacher",
      ],
    ];
    arr = arr.concat(getData());
    let newArr = arr.map((row: string[]) => row.join(","));
    return newArr.join("\r\n");
  };

  const data = getData().map((r: string[]) =>
    r.map((l: string) => l.replace(/"/g, ""))
  );

  if (
    !enrollmentsByStudentThisCycle ||
    !enrollmentsByStudentLastCycle ||
    !settings
  )
    return <h5>Please wait...</h5>;

  return (
    <>
      <div className="row mb-3">
        <div className="col-md-6">
          <h4 className="text-start">Cycle: {settings.cycle}</h4>
        </div>
        <div className="col-md-3">
          <button className="btn btn-primary btn-sm" onClick={toggleSort}>
            Sort by {sortByTeacherRoom ? "teacher/room" : "student name"}
          </button>
        </div>
        <div className="col-md-3">
          <button className="btn btn-primary btn-sm" onClick={downloadData}>
            Download
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 overflow-scroll">
          <table className="table table-condensed table-sm">
            <thead>
              <tr>
                <th>Student ID</th>
                <th className="text-start">Name</th>
                <th className="text-start">Cycle {lastCycle} course</th>
                <th>Teacher / Room</th>
                {/*<th>Section / Teacher / Room</th>*/}
                <th className="text-start">Cycle {thisCycle} course</th>
                <th>Teacher / Room</th>
                {/*<th>Section / Teacher / Room</th>*/}
              </tr>
            </thead>
            <tbody>
              {data.map((s: string[]) => {
                return (
                  <tr
                    key={s[0]}
                    className={s[4] === "Not enrolled" ? "not-enrolled" : ""}
                  >
                    <td>{s[0]}</td>
                    <td className="text-start">{s[1]}</td>
                    <td className="text-start">{s[2]}</td>
                    <td>{s[3]}</td>
                    <td className="text-start">{s[4]}</td>
                    <td>{s[5]}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Passes;
