import React, { useState } from "react";
import Papa from "papaparse";
import { useAppSelector } from "../../utilities/hooks";
import { selectPropertyValue } from "../../store/selectors";
const ParseData = ({
  upload,
  skipFirst,
}: {
  upload: (csv: string[][]) => void;
  skipFirst: boolean;
}) => {
  const [fileName, setFileName] = useState("");
  const [fileError /*, setFileError*/] = useState(false);
  const [csv, setCsv] = useState<string[][]>([]);
  const isUpdating = useAppSelector(selectPropertyValue("isUpdating"));
  const onChangeFile = (evt: React.ChangeEvent<HTMLInputElement>) => {
    let reader = new FileReader();
    reader.onload = function () {
      let csvText = reader.result as string;
      let { data } = Papa.parse<string[]>(csvText);
      let parsed = data.filter((rec) =>
        rec.every((item) => typeof item === "string")
      );
      if (skipFirst) {
        parsed.shift(); // Remove field descriptions in first row
      }
      setCsv(parsed);
    };
    if (!evt || !evt.target || !evt.target.files) return;
    let file = evt.target.files[0];
    if (file) {
      setFileName(file.name);
      reader.readAsText(evt.target.files[0]);
    }
  };

  const _upload = () => {
    upload(csv);
    setFileName("");
    const fileEl = document.getElementById("data-file") as HTMLInputElement;
    if (fileEl) fileEl.value = "";
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          <label htmlFor="data-file" className="btn btn-primary">
            Choose file
          </label>
          <h6
            style={{
              fontSize: "0.80rem",
              borderRadius: "5px",
              marginTop: "5px",
              paddingBottom: "2px",
            }}
          >
            {fileName}
          </h6>
          {fileError ? (
            <h5 className="data-csv-error">Student data error</h5>
          ) : null}
          <input
            style={{ opacity: 0 }}
            id="data-file"
            name="file"
            accept=".csv, .tsv, .text"
            type="file"
            onChange={onChangeFile}
          />
        </div>
        <div className="col-md-6">
          <button
            className={isUpdating ? "btn btn-warning" : "btn btn-primary"}
            onClick={_upload}
            disabled={fileName.length === 0}
          >
            {isUpdating ? "Processing..." : "Upload"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ParseData;
